/* COLORS */

@function color($color-name) {
  @return var(--color-#{$color-name});
}


/* MEDIA QUERIES */
@mixin mobile {
  @media screen and (min-width: $width-mobile) {
    @content
  }
}
@mixin tablet {
  @media screen and (min-width: $width-tablet) {
    @content
  }
}
@mixin desktop {
  @media screen and (min-width: $width-desktop) {
    @content
  }
}

@mixin landscape {
  @media all and (orientation: landscape) {
    @content
  }
}
@mixin portrait {
  @media all and (orientation: portrait) {
    @content
  }
}

@mixin dark-mode {
  @media (prefers-color-scheme: dark) {
    @content
  }
}



/* TITLE UNDERLINE */
@mixin heavy-underline {
  position: relative;
  align-self: flex-start;
  &::after {
    content: "";
    width: 100%;
    height: .5rem;
    background-color: color(primary);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    @include dark-mode {
      background-color: color(secondary);
    }
  }
}


/* BUTTON */

@mixin button {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: all .3s ease;
  align-items: center;
  justify-content: center;
  height: auto;
  font-size: 1rem;
  font-family: Montserrat, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  color: color(text);
}

@mixin button-primary {
  @include button;
  border: 1px solid color(primary);
  background-color: color(primary);
  color: #fff;
  &:hover {
    border-color: color(primary--light);
    background-color: color(primary--light);
    color: #fff;
  }
}
.button-primary {
  @include button-primary;
  padding: 0.5rem;
}
@mixin button-secondary {
  @include button;
  border: 1px solid color(secondary);
  background-color: color(secondary);
  color: #fff;
  &:hover {
    border-color: color(secondary--dark);
    background-color: color(secondary--dark);
    color: #fff;
  }
}

@mixin button-warning {
  @include button;
  border: 3px solid color(warning);
  background-color: #fff;
  color: color(warning);
  &:hover {
    border-color: color(warning);
    background-color: color(warning);
    color: #fff;
  }
}
@mixin button-disabled {
  @include button;
  border: 1px solid color(border--light);
  background-color: color(border--light);
  color: color(border);
  cursor: default;
}
@mixin button-void {
  @include button;
  border: 1px solid color(secondary);
  //background-color: color(border--light);
  color: color(secondary);
  @include dark-mode {
    color: color(default);
  }
  &:hover {
    background-color: color(secondary);
    color: #fff;
  }
}
/* INPUT */


@mixin input-text {
  position: relative;
  border-bottom: 2px solid color(border);
  background-color: transparent;
  cursor: pointer;
  transition: all .3s ease;
  outline: none;
  height: 3rem;
  width: auto;
  border-radius: .5rem .5rem 0 0;
  line-height: 1.2em;
  font-style: normal;
  font-size: 1.2em;
  font-weight: 400;
  text-align: left;
  justify-content: center;
  input, textarea {
    position: absolute;
    height: 2rem;
    width: 100%;
    bottom: 0;
    left: 0;
    font-size: 1rem;
    text-overflow: ellipsis;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
    &::placeholder {
      color: transparent;
      font-family: Montserrat, sans-serif;
      text-transform: none;
    }
  }
  span {
    font-weight: 300;
    transition: all .3s ease;
    position: absolute;
    top: calc(50% - .5rem);
    left: 0;
    z-index: 9;
  }
  &.filled, &.focus {
    span {
      font-size: .75rem;
      top: 0;
    }
    input, textarea {
      &::placeholder {
        color: color(border);
      }
    }
  }
  &.focus {
    border-bottom-color: color(secondary);
    span {
      color: color(secondary);
    }
  }
}
@mixin input-invalid {
  color: color(warning) !important;
  border-bottom-color: color(warning) !important;
  &.focus {
    border-bottom-color: color(text) !important;
  }
  > span {
    color: color(warning);
  }
}

/* TAB BAR */
@mixin tab-bar {
  > ul {
    flex-direction: row;
    background-color: transparentize(#fff, .8);
    border-radius: .25rem;
    @include tablet {
      border-radius: 5rem;
    }
    > li {
      flex-grow: 1;
      flex-basis: 100%;
      > a {
        text-align: center;
        padding: .25rem .5rem;
        font-weight: 500;
        border-radius: .25rem;
        @include tablet {
          font-weight: 700;
          padding: .5rem 1rem;
        }
        &.active {
          background-color: transparentize(#fff, .6);
          @include tablet {
            background-color: #fff;
            margin: -.15rem;
            padding: .65rem 1.15rem;
            border-radius: 5rem;
            color: color(text);
            box-shadow: 0 .25rem .5rem transparentize(#000, .8);
          }
        }
      }
    }
  }
}


/* SEPARATOR */
@mixin has-separator {
  position: relative;
  &::before {
    content: '';
    height: 3px;
    width: 80%;
    background-color: color(border);
    position: absolute;
    opacity: .1;
    bottom: 0;
    left: 10%;
    right: 0;
  }
  &::after {
    content: '';
    height: 3px;
    width: 3rem;
    background-color: color(border);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

/* SAFE AREA FOR APPLE X DEVICES */
@mixin x-safe-area($type, $default, $positions...) {
  @supports($type: max(0px)) {
    @each $pos in $positions {
      #{$type}-#{$pos}: m#{a}x($default, env(safe-area-inset-left));
    }
  }
}
