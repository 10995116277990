@import "styles/variables";
@import "styles/mixins";

.Landing {

  .cta { 
    @include button-primary;
    margin-top: 1rem;
    padding: .75rem 1.5rem;
    background-color: #FE5E41;
    border-color: #FE5E41;
    &:hover {
      background-color: #C74C36;
      border-color: #C74C36;
    }
  }

  margin-top: -5rem;
  > header {
    height: calc(100vh - 8rem);
    position: relative;
    @include tablet {
      height: calc(100vh - 16rem);
      min-height: 45rem;
    }
    > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center 5rem;
      @include tablet {
        object-position: center 5rem;
      }
      @include desktop {
        object-position: center -4rem;
      }
    }
    > div {
      position: absolute;
      height: 100%;
      width: 100%;
      justify-content: center;
      padding: 4rem 1.5rem 1.5rem;
      background-color: transparentize(#000, .9);
      @include tablet {
        align-items: center;
      }
      h1 {
        > span {
          position: relative;
          margin-top: 6rem;
          color: #fff;
          font-size: 2rem;
          line-height: 2.4rem;
          text-align: center;
          text-shadow: 0 0 .5rem rgba(0, 0, 0, .5);
          @include tablet {
            font-size: 3rem;
            line-height: 3.4rem;
            margin-top: 0;
          }
          @include desktop {
            font-size: 4rem;
            line-height: 4.4rem;
          }
          &::after {
            content: "& Visio";
            position: absolute;
            text-shadow: none;
            padding-right: 1rem;
            padding-left: 1rem;
            font-size: 1rem;
            right: -1.2rem;
            bottom: -1.5rem;
            background-color: white;
            color: color(secondary);
            transform: rotate(-10deg);
            @include tablet {
              font-size: 1.8rem;
              right: -1.5rem;
              bottom: -.3rem;
            }
            @include desktop {
              font-size: 2.8rem;
              right: -6rem;
              bottom: 0;
            }
          }
        }
        small {
          color: white;
          text-align: center;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 1.4rem;
          text-transform: none;
          margin-top: 1.7rem;
          padding: 0 1.5rem;
          text-shadow: 0 0 .25rem rgba(0, 0, 0, .7);
          @include tablet {
            margin-top: 1rem;
          }
          @include desktop {
            margin-top: 1rem;
          }
        }
      }
      > a {
        @include button-secondary;
        background-color: #FE5E41;
        border-color: #FE5E41;
        &:hover {
          background-color: #C74C36;
          border-color: #C74C36;
        }
        padding: .75rem 1.5rem;
        margin-top: 4rem;
        margin-bottom: 2rem;
        @include tablet {
          margin-top: 14rem;
          margin-bottom: 0;
        }
        @include desktop {
          margin-top: 16rem;
        }
      }
      .CovidButton {
        border: 0;
        background-color: transparent;
        text-decoration: underline;
        color: #EF476F;
        text-align: center;
        &:hover {
          border-color: transparent;
          background-color: transparent;
          color: #EF476F;
        }
        font-size: 1.5rem;
        padding: .75rem 1.5rem;
        margin-top: .5rem;
        margin-bottom: 1rem;
        @include tablet {
          margin-top: 1rem;
          margin-bottom: 0;
        }
        @include desktop {
          margin-top: 1rem;
        }
      }
    }
  }
  > section {
    padding: 3rem 0;
    @include tablet {
      padding: 3rem calc((100% - #{$width-tablet}) / 2);
    }
    @include desktop {
      padding:  4rem calc((100% - #{$width-desktop}) / 2);
    }
    &.big-title {
      > h2 {
        text-transform: uppercase;
        padding: 0 1.5rem 1.5rem 1rem;
        font-size: 2rem;
        font-weight: 900;
        line-height: 2rem;
        border-left: 1.5rem solid color(text);
        @include tablet {
          font-size: 3rem;
          line-height: 3rem;
        }
        @include desktop {
          width: 100%;
          font-size: 4rem;
          line-height: 4rem;
        }
        > a {
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;
        }
      }
      @include desktop {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
  .landing-marketing {
    background-color: color(border--lighter);
    text-align: center;
    > h2 {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 2rem;
      line-height: 2rem;
      margin-bottom: 1rem;
      padding: 0 1.5rem;
    }
    > div {
      padding: 0 1.5rem;
      @include tablet {
        align-items: center;
      }
      p {
        display: inline;
        line-height: 1.6rem;
        font-size: 1.2rem;
        > strong {
          display: inline;
          line-height: 1.6rem;
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
    }
  }
  .landing-description {
    > h2 {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 2rem;
      line-height: 2rem;
      margin-bottom: 1rem;
      padding: 0 1.5rem;
      text-align: center;
    }
    > ul {
      padding: 0 1.5rem;
      @include tablet {
        flex-direction: row;
      }
      > li {
        padding: 1rem 0;
        @include tablet {
          flex-basis: 100%;
          padding: 1rem;
        }
        h3 {
          @include heavy-underline;
          text-transform: uppercase;
          margin-bottom: .5rem;
        }
        p {
          span {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .landing-feedback {
    > h2 {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 2rem;
      line-height: 2rem;
      margin-bottom: 1rem;
      padding: 0 1.5rem;
      text-align: center;
    }
    > em {
      font-weight: 400;
      text-align: center;
      margin-top: .25rem;
      margin-bottom: 1.5rem;
    }
    > div {
      align-items: center;
      > a {
        padding: .5rem 1.5rem .5rem 1.5rem;
        @include button-primary;
        > em {
          text-transform: none;
          text-decoration: underline;
        }
      }
    }
    > ul {
      padding: 0 1.5rem;
      @include tablet {
        flex-direction: row;
      }
      > li {
        padding: 1rem 0;
        @include tablet {
          flex-basis: 100%;
          padding: 1rem;
        }
        p {
          display: inline;
          strong {
            display: inline;
          }
        }
      }
    }
  }
  .landing-search {
    .LandingMap {
      @include desktop {
        flex-direction: row;
        flex-grow: 1;
        height: 60vh;
      }
      > div {
        @include desktop {
          &:first-of-type {
            width: 60%;
          }
          & + div {
            width: 40%;
          }
        }
        .GoogleMapsDisplay {
          height: 60vh;
          border-left: 1.5rem solid color(text);
        }
        > aside {
          flex-direction: row;
          margin-top: -5rem;
          align-items: center;
          z-index: 1;
          background-image: url(./icons/chevron-right.svg);
          background-position: right .5rem center;
          background-color: color(background);
          background-repeat: no-repeat;
          background-size: 2rem;
          position: relative;
          @include tablet {
            border-left: 1.5rem solid color(text);
            margin-right: 1.5rem;
          }
          @include desktop {
            margin-top: -10rem;
            margin-right: 0;
          }
          > img {
            width: 33%;
            height: 5rem;
            object-fit: cover;
            @include tablet {
              height: 10rem;
            }
          }
          h3 {
            text-transform: uppercase;
            padding: .5rem 2rem .5rem 1rem;
          }
          address {
            padding: 0 2rem 0 1rem;
            display: none;
            @include tablet {
              display: flex;
            }
          }
          > a {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
        }
        > a {
          @include button-secondary;
          background-color: #FE5E41;
          border-color: #FE5E41;
          &:hover {
            background-color: #C74C36;
            border-color: #C74C36;
          }
          padding: .75rem 1.5rem;
          margin: 1rem 1.5rem 0;
          flex-shrink: 0;
          @include tablet {
            align-self: center;
          }
        }
      }
      .SeanceList {
        padding-top: 0;
        overflow-y: auto;
				.SeancePreview {
					@include desktop {
						> section {
							> div {
								flex-direction: column;
								align-items: flex-start;
							}
						}
					}
				}
      }
    }
  }
  .landing-cta {
    background-color: color(border--lighter);
    text-align: center;
    > h2 {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 2rem;
      line-height: 2rem;
      margin-bottom: 1rem;
      padding: 0 1.5rem;
    }
    > div {
      padding: 0 1.5rem;
      @include tablet {
        align-items: center;
      }
      strong {
        font-size: 1.5rem;
        line-height: 1.7rem;
        margin-bottom: 1rem;
      }
      a {
        @include button-primary;
        margin-top: 1rem;
        padding: .75rem 1.5rem;
        background-color: #FE5E41;
        border-color: #FE5E41;
        &:hover {
          background-color: #C74C36;
          border-color: #C74C36;
        }
      }
    }
  }
}
