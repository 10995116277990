@import "./styles/variables";
@import "./styles/mixins";

.Header {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  @include desktop {
		position: absolute;
	}
  > section {
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    height: 5rem;
    background-color: transparentize(#fff, .2);
    -webkit-backdrop-filter: blur(1rem) saturate(180%);
    backdrop-filter: blur(1rem) saturate(180%);
    padding: 0 1.5rem 0 1rem;
    position: relative;
    @include tablet {
      padding: 0 calc((100% - #{$width-tablet}) / 2 + 1.5rem);
    }
    @include desktop {
      padding: 0 calc((100% - #{$width-desktop}) / 2 + 1.5rem);
    }
    @include dark-mode {
      background-color: transparentize(#333, .3);
    }
    .main-logo {
			height: 6rem;
			width: 14rem;
			margin: 0 0 0 -1.5rem;
			background-image: url(./images/logo-full.svg);
			background-repeat: no-repeat;
			background-size: cover;
			color: transparent;
			@include dark-mode {
				background-image: url(./images/logo-full--light.svg);
			}
    }
		.desktop-nav {
			display: none;
			height: 100%;
			margin-left: auto;
			@include desktop {
				display: flex;
			}
			> ul {
				flex-direction: row;
				height: 100%;
				> li {
					a {
						font-size: 0.8rem;
						height: 100%;
						justify-content: center;
						padding: 0 1rem;
						font-weight: 700;
						text-transform: uppercase;
						color: color(header);
						@include dark-mode {
							color: color(text)
						}
						&:hover {
							@include heavy-underline;
						}
					}
				}
			}
		}
    > button.show-menu {
      background-color: color(primary);
      width: 4.5rem;
      height: 4.5rem;
      align-self: flex-start;
      align-items: center;
      justify-content: center;
      margin-left: auto;
			margin-top: 0;
      border-radius: 3rem;
      flex-shrink: 0;
			@include desktop {
				margin-left: 2rem;
			}
      > img {
        width: 2rem;
      }
    }
  }

  &.adminViewer {
    > section {
      background:orange;
      > button.show-menu {
        background-color: red;
      }
    }
  }
}