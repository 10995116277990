@import "../styles/variables";
@import "../styles/mixins";

.MsgPage {
	padding: 1rem;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	background-color: color(background);
	> .loader, > img {
		align-items: center;
		justify-content: center;
		width: 4rem;
		height: 4rem;
		margin-bottom: 1rem;
	}
	> p {
		padding: 1rem 0;
		text-align:center;
	}
	> button {
		@include button-secondary;
		padding: .75rem 1.5rem;
		margin-top: 2rem;
	}
}

.spinner {
	width: 3rem;
	height: 3rem;
	background-color: color(text);
	border-radius: 100%;
	animation: scaleout 1.0s infinite ease-in-out;
}

.loader {
	.spinner + .spinner {
		animation-delay: .25s;
		margin-top: -3rem;
	}
}
