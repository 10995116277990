@import "./styles/variables";
@import "./styles/mixins";

.SignUp {
  margin-top: -5rem;
  padding: 8rem 0 2rem;
  position: relative;
  background-image: url(./images/hero.jpg);
  background-size: cover;
  background-position: center;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
  }
  > h1 {
    padding: 0 1.5rem 2rem;
    color: #fff;
    text-shadow: 0 0 .5rem color(secondary);
    position: relative;
    @include tablet {
      width: $width-tablet;
      margin: 0 auto;
    }
  }
  > form {
     a {
      display: inline;
      text-decoration: underline;
    }
    padding: 1.5rem;
    margin: 0 1.5rem;
    background-color: color(background);
    position: relative;
    align-items: flex-start;
    @include tablet {
      width: calc(#{$width-tablet} - 3rem);
      margin: 0 auto;
    }
    label:not(.has-checkbox) {
      margin-bottom: 1rem;
      width: 100%;
    }
    .choice {
      flex-direction: row;
      flex-wrap: wrap;
      input {
        flex-shrink: 0;
      }
      &.invalid {
        color: color(warning);
        p {
          font-weight: 500;
        }
      }
      > p {
        width: 100%;
        margin-bottom: .5rem;
      }
    }
    .has-checkbox {
      flex-direction: row-reverse;
      align-items: baseline;
      margin-right: 1rem;
      span {
        margin-left: .5rem;
      }
      input {
        flex-shrink: 0;
      }
    }
    > button {
      @include button-secondary;
      padding: .75rem 1.5rem;
      margin: 2rem auto 0;
      &:disabled {
        @include button-disabled;
      }
    }
    .existing-email-modal {
      p {
        margin-bottom: 1rem;
      }
    }
  }
  .form-error {
    padding-top: 1rem;
    
    span {
      flex-direction: row;
      justify-content: center;
      text-align:center;
    }
    a {
      font-weight: bold;
    }
  }
}