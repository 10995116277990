@import './styles/variables';
@import './styles/mixins';

.ChallengeList {
	margin-top: -5rem;
	> header {
		position: relative;
		> img {
			height: 50vh;
			width: 100%;
			object-fit: cover;
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);
		}
		> h1 {
			position: absolute;
			top: 0;
			left: 0;
			padding: 4rem 1.5rem 1.5rem;
			text-align: center;
			height: 100%;
			width: 100%;
			background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
			color: #fff;
			align-items: center;
			justify-content: center;
			text-shadow: 0 0 .5rem color(secondary);
			font-size: 2.25rem;
			line-height: 2.5rem;
		}
	}
	> aside {
		z-index: 1;
		border-left: 1.25rem solid color(text);
		padding: 1.5rem 1.5rem 1rem 1rem;
		width: calc(100% - 3rem);
		margin-top: -3rem;
		background-color: color(background);
		align-items: flex-start;
		font-weight: 500;
		@include tablet {
			width: $width-tablet;
			margin: -3rem auto 1rem;
		}
		@include desktop {
			width: $width-desktop;
			flex-direction: row;
		}
		> a {
			@include button-secondary;
			padding: .75rem 1.5rem;
			margin-top: 1rem;
			flex-shrink: 0;
			@include desktop {
				margin-top: 0;
			}
		}
	}
	> section {
		padding: 1.5rem 0;
		@include tablet {
			width: $width-tablet;
			margin: 0 auto;
		}
		@include desktop {
			width: $width-desktop;
		}
		> div {
			padding: 0 1.5rem;
			p {
				margin-bottom: .5rem;
				em {
					font-weight: 500;
				}
			}
		}
		.challenge-enterprise-link {
			@include button-secondary;
			padding: .75rem 1.5rem;
			margin: 1.5rem;
			@include tablet {
				align-self: center;
			}
		}
		> ul {
			padding: 0 1.5rem;
			@include tablet {
				flex-direction: row;
				flex-wrap: wrap;
			}
			> li {
				padding: 1rem 0;
				@include tablet {
					padding: 1rem;
					width: 50%;
					&:nth-child(2n) {
						border-left: 1px solid color(border);
					}
				}
				@include desktop {
					width: calc(100% / 3);
					border-left: 1px solid color(border);
					&:nth-child(3n + 1) {
						border: none;
					}
				}
				& + li {
					border-top: 1px solid color(border);
					@include tablet {
						border-top: none;
					}
				}
			}
		}
	}
	> .separator-img {
		height: 60vh;
		object-fit: cover;
	}
	> section.challenges-contact {
		padding: 4rem 0;
		@include desktop {
			flex-direction: row;
			flex-wrap: wrap;
		}
		> h2 {
			text-transform: uppercase;
			padding: 0 1.5rem 1.5rem 1rem;
			font-size: 2rem;
			font-weight: 900;
			line-height: 2rem;
			border-left: 1.5rem solid color(text);
			@include tablet {
				font-size: 3rem;
				line-height: 3rem;
			}
			@include desktop {
				width: 100%;
				font-size: 4rem;
				line-height: 4rem;
			}
		}
		> div {
			&:first-of-type {
				border-left: 1.5rem solid color(text);
				padding: 0 1rem 1rem;
				@include desktop {
					padding: 1rem;
				}
			}
			& + div {
				align-items: center;
				@include desktop {
					align-items: flex-start;
				}
				> a {
					flex-direction: row;
					align-items: center;
					font-weight: 700;
					margin: .5rem 0;
					img {
						height: 1.5rem;
						margin-right: .5rem;
					}
				}
			}
		}
	}
}

.ChallengePreview {
	flex-direction: row;
	position: relative;
	@include tablet {
		flex-direction: column;
		&:hover {
			> header {
				img {
					filter: saturate(0);
				}
			}
			> section {
				h3 {
					text-decoration: underline;
				}
			}
		}
	}
	> header {
		width: 33%;
		padding-top: 33%;
		flex-shrink: 0;
		margin-right: 1rem;
		position: relative;
		@include tablet {
			width: 100%;
			padding-top: 50%;
			@include tablet {
				margin-bottom: .5rem;
			}
		}
		> img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	> section {
		> h3 {
			margin-bottom: .5rem;
		}
		> ul {
			flex-direction: row;
			flex-wrap: wrap;
			margin-bottom: .5rem;
			> li {
				background-color: color(header);
				font-size: .8rem;
				font-weight: 500;
				color: #fff;
				padding: 0 .5rem;
				border-radius: .25rem;
				margin-right: .25rem;
			}
		}
		> p {
			flex-direction: row;
			font-weight: 500;
			img	{
				width: 1.25rem;
				margin-right: .5rem;
			}
		}
	}
	> a {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
}