@import "./styles/variables";
@import "./styles/mixins";

.Contact {
	background-color: #424B54;
	color: white;
	padding: 1rem;
	@include tablet {
		padding: 2rem;
	}
	@include desktop {
		padding: 10rem;
	}
	.main {
		display: flex;
		justify-content: center;
		@include tablet {
			flex-direction: row;
		}
		gap: 1rem;
	}
	.section {
		display: flex;
		flex-grow: 1;
		justify-content: space-between;
		padding-top: 2rem;
		@include desktop {
			max-width:40%;
		}
		p {
			flex-grow:1;
		}
		h1,p,button {
			display: flex;
		}
		button {
			margin: 1rem 0;
		}
	}
}
