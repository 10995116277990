@import "./styles/variables";
@import "./styles/mixins";

.PayBookModal {
	section {
		p {
			margin-bottom: 1rem;
			strong {
				margin: .5rem 0;
			}
		}
		.error {
			font-weight: 500;
			color: color(warning);
		}
	}

	&.PayBookModal_Failure {
		.MsgPage {
			padding: 0;
		}
	}
}

.pay-booking-modal-success {
	> section {
		> div {
			>.MsgPage {
				padding: 0;
			}
			> a {
				@include button-primary;
				padding: .75rem 1.5rem;
				margin-bottom: -1rem;
			}
		}
	}
}

.pay-booking-modal-payment {
	flex-direction: column;
	> div:first-child {
		margin-bottom: .5rem;
	}
	.registered-cb {
		flex-direction: row;
		align-items: center;
		justify-content: center;

		&.selected {
			background-color: color(highlight);
			padding: .75rem 1rem;
		}
		> img {
			height: 2rem;
		}
		> span {
			margin-left: .5rem;
		}
	}
}

.PayButton {
	@include button-primary;
	padding: .75rem 1.5rem;
	margin-bottom: -1rem;
}

