@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-weight: 300;
  font-style: normal;
  src: url(../fonts/montserrat-300.ttf);
}
@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-weight: 300;
  font-style: italic;
  src: url(../fonts/montserrat-300-italic.ttf);
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/montserrat-500.ttf);
}
@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-weight: 500;
  font-style: italic;
  src: url(../fonts/montserrat-500-italic.ttf);
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/montserrat-700.ttf);
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-weight: 900;
  font-style: normal;
  src: url(../fonts/montserrat-900.ttf);
}

@font-face {
  font-display: swap;
  font-family: "Helvetica Neue";
  font-weight: 300;
  font-style: normal;
  src: url(../fonts/helveticaneue-300.otf);
}

@font-face {
  font-display: swap;
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/roboto-condensed-700.ttf);
}
