@import "../styles/variables";
@import "../styles/mixins";

.BasicSearch {
	input {
		font-size: 1rem;
		font-weight: 300;
	}
	li:hover {
		background-color: color(primary);
		cursor: pointer;
	}
}

.BasicSearchInput {
  outline: 0  !important;
  border-width: 0 0 2px  !important;
  border-color: color(primary)  !important;
}