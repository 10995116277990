@import './styles/variables';
@import "./styles/mixins";

.Coach {
	margin-top: -5rem;
	> header {
		background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
		color: #fff;
		padding: 8.5rem 1.5rem 1.5rem;
		align-items: center;
		position: relative;
		> button {
			@include button;
			position: absolute;
			color: #fff;
			left: 1rem;
			top: 6rem;
			padding-left: .75rem;
			background-image: url(./icons/chevron-left--light.svg);
			background-position: left -.5rem center;
			background-repeat: no-repeat;
			background-size: 1.25rem;
			@include tablet {
				left: calc((100% - #{$width-tablet}) / 2 + 1.5rem);
			}
		}
		> img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			z-index: -1;
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);
		}
		> div {
			align-items: center;
			img {
				$rad: 8rem;
				width: $rad;
				height: $rad;
				border-radius: $rad;
				margin-bottom: 1rem;
				object-fit: cover;
			}
		}
	}
	> section {
		padding: 1.5rem;
		align-items: flex-start;
		@include tablet {
			width: $width-tablet;
			margin: 0 auto;
		}
		> h2 {
			text-transform: uppercase;
			margin-bottom: 1rem;
			@include heavy-underline;
		}
		> p {
			white-space: pre-wrap;
		}
		.Planning {
			margin: 0 -1.5rem;
			width: calc(100% + 3rem);
		}
	}
}