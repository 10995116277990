@import "./styles/variables";
@import "./styles/mixins";

.PartnerOfferList {
	background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
	padding: 5rem 0 2rem;
	margin-top: -5rem;
	> h1 {
		color: #fff;
		padding: 2rem 1.5rem 0;
		@include tablet {
			margin: 0 auto;
			width: calc(#{$width-tablet} - 3rem);
		}
		@include desktop {
			width: calc(#{$width-desktop} - 3rem);
		}
	}
	> ul {
		padding: 1.5rem;
		@include tablet {
			margin: 0 auto;
			width: calc(#{$width-tablet} - 3rem);
			flex-direction: row;
			flex-wrap: wrap;
		}
		@include desktop {
			width: calc(#{$width-desktop} - 3rem);
		}
		> li {
			margin-bottom: 1.5rem;
			@include tablet {
				width: calc(50% - .75rem);
				&:nth-child(2n) {
					margin-left: 1.5rem
				}
			}
			@include desktop {
				width: calc((100% / 3) - 1rem);
				margin-left: 1.5rem;
				&:nth-child(3n + 1) {
					margin-left: 0;
				}
			}
		}
	}
}

.PartnerOfferArticle {
	background-color: color(background);
	flex-grow: 1;
	> header {
		position: relative;
		background-color: #fff;
		img {
			height: 10rem;
			padding: 1.5rem;
			width: auto;
			object-fit: contain;
		}
		> div {
			background-color: color(secondary);
			color: #fff;
			font-size: 2rem;
			font-weight: 900;
			line-height: 2rem;
			padding: 1rem;
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
	> section {
		padding: 1rem 1.5rem 1.5rem;
		h3 {
			margin-bottom: 1rem;
		}
		p {
			white-space: pre-wrap;
		}
	}
}