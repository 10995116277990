@import './styles/variables';
@import './styles/mixins';

.WebApp {
	margin-top: -5rem;
	padding: 6rem 1.5rem;
	background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
	> h1 {
		color: #fff;
		margin-bottom: 1rem;
		@include tablet {
			width: calc(#{$width-tablet} - 3rem);
			margin: 1rem auto;
		}
	}
	> section {
		background-color: color(background);
		padding: 1.5rem;
		@include tablet {
			width: calc(#{$width-tablet} - 3rem);
			margin: 0 auto;
		}
		> img {
			width: 5rem;
			border-radius: 1rem;
			margin: 0 auto 1rem;
		}
		> p {
			margin-bottom: 1rem;
		}
		h2 {
			@include heavy-underline;
			margin-bottom: .5rem;
		}
		> div {
			margin-bottom: 1rem;
			> p {
				display: inline;
				> * {
					display: inline;
				}
				> img {
					width: 1.5rem;
					position: relative;
					margin-bottom: -.25rem;
				}
			}
		}
	}
}