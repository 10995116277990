@import "./styles/variables";
@import "./styles/mixins";

.DashboardMenu {
	@include tab-bar;
}

.Dashboard {
	margin-top: -5rem;
	> header {
		position: relative;
		padding: 7.5rem 1.5rem 1.5rem;
		width: 100%;
		//background-color: #424B54;
		background-image: linear-gradient(to left top, #{transparentize(#00ff83, .5)}, #{transparentize(#0b7a75, .3)});
		color: #fff;
		@include tablet {
			padding: 7.5rem calc((100% - #{$width-tablet}) / 2 + 2rem) 3rem;
		}
		@include desktop {
			flex-direction: row;
			padding: 7.5rem calc((100% - #{$width-desktop}) / 2 + 2rem) 3rem;
			align-items: center;
		}
		> button {
			margin-left: auto;
			img {
				width: 1.5rem;
			}
		}
		> nav {
			padding: 1rem 0 0;
			@include desktop {
				width: $width-desktop;
			}
			> ul {
				background-color: #424B54;
				> li {
				}
			}
		}
	}
	> section {
		padding: 1.5rem 0;
		@include tablet {
			width: $width-tablet;
			margin: 0 auto;
		}
		> h2 {
			@include heavy-underline;
			text-transform: uppercase;
			margin: 0 1.5rem 1rem;
		}
		> div {
			padding: 0 1.5rem;
		}
		&.account-plan {
			a {
				font-weight: 700;
				margin: 0 auto;
			}
		}
	}
}

.ReferralDetails {
	display: grid;
	grid-template-columns: 80% 20%;
	grid-column-gap: 1rem;
	> p {
		grid-area: 1 / 1 / 2 / 2;
		background-color: color(highlight);
		padding: 1rem 1rem .5rem;
	}
	> strong {
		grid-area: 1 / 2 / 3 / 3;
		text-align: center;
		justify-content: center;
	}
}

.ReferralView {
	> p {
		padding: 1rem;
	}
	.ReferralPreview {
		> h2 {
			@include heavy-underline;
			text-transform: uppercase;
			margin-bottom: 1rem;
		}
		> p {
			margin-bottom: 1rem;
		}
		.referral-code {
			align-items: center;
			margin-bottom: 2rem;
			@include tablet {
				flex-direction: row;
				align-items: center;
				justify-content: center;
			}
			> input {
				font-family: "Roboto Condensed", sans-serif;
				width: 100%;
				text-align: center;
				font-weight: 700;
				font-size: 3rem;
				line-height: 4rem;
				@include tablet {
					width: 20rem;
					text-align: left;
					font-size: 4rem;
				}
			}
			> div {
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				@include tablet {
					justify-content: flex-start;
					width: 15rem;
				}
				> strong {
					width: 100%;
					margin: 1rem 0 .5rem;
					text-align: center;
					@include tablet {
						margin: 0 0 .5rem;
						flex-grow: 1;
						text-align: left;
					}
				}
				> a, > button {
					flex-direction: row;
					font-weight: 500;
					align-items: center;
					> img {
						width: 1.5rem;
						margin-right: .5rem;
					}
				}
				> button {
					margin-right: 2rem;
				}
			}
		}
	}
}

.ProfilePreview {
	align-items: center;
	width: inherit;
	margin-bottom: 1rem;
	@include tablet {
		flex-direction: row;
	}
	@include desktop {
		margin-bottom: 0;
	}
	> img {
		width: 6rem;
		height: 6rem;
		border-radius: 6rem;
		margin-bottom: .5rem;
		@include tablet {
			margin-right: 2rem;
		}
	}
	> p {
		align-items: center;
		@include tablet {
			align-items: flex-start;
		}
		.user-profile-link {
			flex-direction: row;
			align-items: center;
			position: relative;
			margin-bottom: 1rem;
			strong {
				font-size: 1.4rem;
				line-height: 1.6rem;
			}
			img {
				position: absolute;
				width: 1.5rem;
				right: -2rem;
			}
		}
		.user-offer-link {
			align-items: center;
			text-align: center;
			font-size: 1.2rem;
			line-height: 1.2rem;
			@include tablet {
				text-align: left;
				align-items: flex-start;
				&:hover {
					strong {
						text-decoration: underline;
					}
				}
			}
			strong {
				margin-top: .5rem;
				font-size: 1.2rem;
				line-height: 1.2rem;
				position: relative;
			}
			img {
				position: absolute;
				width: 1.5rem;
				right: -2rem;
				bottom: 0;
			}
		}
	}
}

.CreditsPreview {
	padding: 1rem 0;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.stats-uc {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	> div {
		margin-top: .5rem;
		background-image: linear-gradient(to left top, #{transparentize(#00ff83, .5)}, #{transparentize(#0b7a75, .3)});
	}
	@include tablet {
		> div {
			margin-top: 0;
		}
		flex-direction: row;
	}
}

.credits-uc {
	$w: 9rem;
	width: $w;
	height: $w;
	border-radius: $w;
	background-color: color(secondary);
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	> p {
		color: #fff;
		align-items: center;
		strong {
			font-size: 2rem;
			line-height: 2.5rem;
			font-weight: 900;
		}
		span {
			line-height: 1rem;
			text-align: center;
		}
	}
}

.BookingsPreview {
	.SeanceList {
		padding: 0 1.5rem 1.5rem;
	}
	.show-past-seance {
		@include button;
		padding: .75rem 1.5rem;
		margin: 0 1.5rem 1.5rem;
		background-color: color(border--light);
		@include tablet {
			align-self: center;
		}
	}
	.bookings-empty {
		padding-bottom: 1rem;
		align-items: center;
		> p {
			margin-bottom: .5rem;
		}
		> a {
			@include button-secondary;
			padding: .75rem 1.5rem;
		}
	}
}
