@import "./styles/variables";
@import "./styles/mixins";

.FormField {
	&:not(.has-checkbox) {
		@include input-text;
	}
	&.invalid {
		@include input-invalid;
	}
}