html, body,
main, div, span, button,
applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a,
abbr, acronym, address, big, cite, code, del,
dfn, em, img, ins, kbd, q, s, samp, small, strike,
strong, sub, sup, tt, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend,
caption, article,
aside, canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 1rem;
  vertical-align: baseline;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

html {
  font-size: $rem;
}

a {
  text-decoration: none;
  &:active {
    text-decoration: underline;
  }
}

ol, ul {
  list-style: none;
}

address {
  font-style: normal;
}

blockquote, q {
  quotes: none;
}

blockquote {
  &:before, &:after {
    content: '';
    content: none;
  }
}

q {
  &:before, &:after {
    content: '';
    content: none;
  }
}

label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: none;
    font-size: 1rem;
    vertical-align: baseline;
    box-sizing: border-box;
  }
}

button, input:not([type="radio"]):not([type="submit"]):not([type="checkbox"]), summary, select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
