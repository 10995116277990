@import "./styles/variables";
@import "./styles/mixins";

.SanitaryCrisis {
	margin-top: -5rem;
	> header {
		height: calc(100vh - 8rem);
		position: relative;
		@include tablet {
			height: calc(100vh - 25rem);
		}
		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		> div {
			position: absolute;
			height: 100%;
			width: 100%;
			justify-content: center;
			padding: 4rem 0 1.5rem;
			background-color: transparentize(#000, .85);
			@include tablet {
				align-items: center;
			}
			h1 {
				color: #fff;
				font-size: 2rem;
				line-height: 2.4rem;
				align-items: center;
				text-shadow: 0 0 .5rem rgba(0, 0, 0, .5);
				@include mobile {
					width: calc(#{$width-mobile} - 2rem);
					margin: 1rem auto;
				}
				small {
					font-weight: 700;
					font-size: 2rem;
					line-height: 2.5rem;
					margin-top: 4rem;
					border-left: .5rem solid #fff;
					padding-left: 1rem;
					text-shadow: 0 0 .25rem rgba(0, 0, 0, .7);
				}
			}
		}
	}
	> ul {
		@include tablet {
			width: $width-tablet;
			margin: 1rem auto;
		}
		@include desktop {
			width: $width-desktop;
			margin: 2rem auto;
		}
		> li {
			padding: 1rem 1.5rem;
			h2 {
				@include heavy-underline;
				text-transform: uppercase;
				margin-bottom: 1rem;
			}
			> div {
				margin-bottom: 1rem;
				> div, section {
					margin-bottom: .5rem;
					h3 {
						margin-bottom: 1.5rem;
					}
					em {
						margin-bottom: 1.5rem;
					}
					> div {
						margin-bottom: 1rem;
					}
					p {
						display: inline;
						> span {
							display: inline;
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

.ListedContent {
	> li {
		display: inline;
		strong {
			display: inline;
		}
		margin-bottom: 1rem;
		p {
			display: inline;
			> span {
				display: inline;
				text-decoration: underline;
			}
			> a {
				display: inline;
				text-decoration: underline;
				color: color(li);
			}
		}
	}

	> ul {
		margin-bottom: .5rem;
	}
}
