@import "./styles/variables";
@import "./styles/mixins";

.Concept {
  > header {
    margin-top: -5rem;
    position: relative;
    height: calc(100vh - 8rem);
    align-items: center;
    justify-content: center;
    @include tablet {
      height: calc(100vh - 16rem);
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    > div {
      position: absolute;
      h1 {
        color: white;
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.5rem;
        border-left: 0.5rem solid #fff;
        padding-left: 1rem;
        text-shadow: 0 0 .25rem rgba(0, 0, 0, 70%);
      }
    }
  }
}

.BetterYourself {
  background-color: color(footer);
  color: white;
  padding: 2rem 0 0;
  @include tablet {
    padding: 3rem calc((100% - #{$width-tablet}) / 2);
  }
  > img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  > div {
    border-left: 1rem solid white;
    padding-left: 1rem;
    margin-bottom: 2rem;
    > h2 {
      font-weight: 900;
      text-transform: uppercase;
      font-size: 3rem;
      line-height: 4rem;
      margin-bottom: 1rem;
    }
  }
}

.SeanceTiming {
  padding: 2rem 0;
  @include tablet {
    padding: 3rem 0;
  }
  h2 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 2.5rem;
    line-height: 3rem;
    padding: 1rem;
    @include tablet {
      padding: 0 calc((100% - #{$width-tablet}) / 2)
    }
  }
  > p {
    display: inline;
    padding: 1rem;
    @include tablet {
      padding: 1rem calc((100% - #{$width-tablet}) / 2)
    }
    > * {
      display: inline;
    }
  }
  > ol {
    width: 100%;
    overflow-x: auto;
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: -2rem;
    @include tablet {
      margin: 1rem auto;
      width: auto;
    }
    > li {
      width: calc(#{$width-mobile} - 7rem);
      flex-shrink: 0;
      height: 70vh;
      position: relative;
      > img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(100%);
      }
      article {
        position: absolute;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: white;
        &.warmup {
          background-color: transparentize(#ef476f, .5);
        }
        &.main-training {
          background-color: transparentize(#28afb0, .5);
        }
        &.stretching {
          background-color: transparentize(#ffd166, .5);
        }
        > em {
          font-size: 4rem;
          line-height: 4rem;
          font-style: normal;
          font-weight: 900;
          margin-bottom: 1rem;
          color: transparent;
          -webkit-text-stroke-color: white;
          -webkit-text-stroke-width: 2px;
        }
        > b {
          font-size: 1.75rem;
          margin-bottom: 1rem;
        }
        > span {
          font-weight: 700;
          font-size: 1.25rem;
        }
      }
    }
  }
}

.Advantages {
  background-color: color(border--lighter);
  padding: 2rem 0;
  @include tablet {
    padding: 3rem calc((100% - #{$width-tablet}) / 2)
  }
  h2 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 2.5rem;
    line-height: 3rem;
    padding: 1rem;
  }
  > p {
    padding: 1rem;
    display: inline;
    font-weight: 600;
    > strong {
      font-weight: 900;
      display: inline;
    }
    > ul {
      display: flex;
      flex-direction: row;
      margin-top: .5rem;
      flex-wrap: wrap;
      > li {
        width: calc((100% / 2) - 1rem);
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        margin: 1.5rem .5rem;
        @include desktop {
          width: calc(100% / 4 - 1.5rem);
        }
        > img {
          width: 8em;
          height: 8rem;
          border-radius: 8rem;
          object-fit: cover;
          margin-bottom: .5rem;
        }
        > b {
          text-align: center;
        }
      }
    }
  }
}