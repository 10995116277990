@import "./styles/variables";
@import "./styles/mixins";

.SearchPage {
  .SearchTools {
    position: sticky;
    top: 5rem;
    z-index: 9;
    @include desktop {
      top: 0;
    }
  }
  .SeanceSearch {
    flex-grow: 1;
    > div {
      flex-grow: 1;
    }
  }
}