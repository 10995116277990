@import './styles/variables';
@import "./styles/mixins";

.SearchTools {
	> section {
		padding-top: .5rem;
		background-color: transparentize(#fff, .2);
		-webkit-backdrop-filter: blur(1rem) saturate(180%);
		backdrop-filter: blur(1rem) saturate(180%);
		@include tablet {
			padding: 1rem calc((100% - #{$width-tablet}) / 2 + 1.5rem) 0;
		}
		@include dark-mode {
			background-color: transparentize(#333, .2);
		}
	}
}