@import './styles/variables';
@import "./styles/mixins";

.CoachList {
  margin-top: -5rem;
  > header {
    position: relative;
    > img {
      height: 50vh;
      width: 100%;
      object-fit: cover;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    > h1 {
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 4rem;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
      color: #fff;
      align-items: center;
      justify-content: center;
      text-shadow: 0 0 .5rem color(secondary);
      font-size: 2.25rem;
    }
  }

  > aside {
    z-index: 1;
    border-left: 1.25rem solid color(text);
    padding: 1.5rem 1rem .5rem;
    width: calc(100% - 3rem);
    margin-top: -3rem;
    background-color: color(background);
    align-items: flex-start;
    @include tablet {
      width: $width-tablet;
      margin: -3rem auto 1rem;
    }
    > p {
      display: inline;
      margin-bottom: .5rem;
      > a {
        display: inline;
        font-weight: 500;
      }
      > em {
        margin-top: .5rem;
        color: color(secondary);
        font-weight: 500;
      }
    }
  }

  > section {
    padding: 1.5rem;
    @include tablet {
      width: $width-tablet;
      margin: 0 auto;
    }
    > ul {
      @include mobile {
        flex-direction: row;
        flex-wrap: wrap;
      }
      > li {
        padding: 1.5rem;
        align-items: center;
        @include mobile {
          width: 50%;
        }
        @include tablet {
          width: calc(100% / 3);
        }
      }
    }
  }
}

.CoachPreview {
  position: relative;
  align-items: center;
  @include tablet {
    &:hover {
      > header {
        img {
          filter: saturate(0);
        }
        h2 {
          text-decoration: underline;
        }
      }
    }
  }
  > header {
    align-items: center;
    img {
      $avatar-width: 8rem;
      width: $avatar-width;
      height: $avatar-width;
      border-radius: $avatar-width / 2;
      object-fit: cover;
    }
    h2 {
      margin-top: .5rem;
    }
  }
  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}