@import "styles/variables";
@import "styles/mixins";

.TeacherDisplay {
  margin-top: -5rem;
  > header {
    position: relative;
    > button {
      @include button;
      position: absolute;
      color: #fff;
      left: 1rem;
      top: 6rem;
      padding-left: .75rem;
      background-image: url(./icons/chevron-left--light.svg);
      background-position: left -.5rem center;
      background-repeat: no-repeat;
      background-size: 1.25rem;
      z-index: 1;
      @include tablet {
        left: calc((100% - #{$width-tablet}) / 2 + 1.5rem);
      }
    }
    > img {
      height: 50vh;
      width: 100%;
      object-fit: cover;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    > h1 {
      text-transform: none;
      position: absolute;
      top: 0;
      left: 0;
      padding: 4rem 1.5rem .5rem;
      height: 100%;
      width: 100%;
      text-align: center;
      background-image: linear-gradient(to left top, #{transparentize(#00ff83, .6)}, #{transparentize(#0b7a75, .4)});
      color: #fff;
      align-items: center;
      justify-content: center;
      text-shadow: 0 0 .5rem color(secondary);
      font-size: 2.25rem;
      line-height: 2.5rem;
      > small {
        line-height: 1.2rem;
        margin-top: .5rem;
      }
      > img {
        height: 10rem;
        width: 10rem;
        border-radius: 10rem;
        object-fit: cover;
      }
    }
  }
  > aside {
    z-index: 1;
    border-left: 1.25rem solid color(text);
    padding: 1rem 1.5rem .5rem 1rem;
    width: calc(100% - 3rem);
    margin-top: -5rem;
    background-color: color(background);
    @include tablet {
      width: $width-tablet;
      margin: -3rem auto 1rem;
      align-items: flex-start;
    }
  }
  > section {
    padding: .5rem 1.5rem 0 1.5rem;
    align-items: flex-start;
    @include tablet {
      width: $width-tablet;
      margin: 0 auto;
    }
    > h4 {
      text-transform: uppercase;
      margin-bottom: 1rem;
      @include heavy-underline;
    }
    > ul {
      width: 100%;
      align-items: center;
      > li {
        width: 100%;
        text-align: center;
        @include tablet {
          width: 50%;
          margin: 0 auto;
        }
        .LessonButton {
          @include button-void;
          text-transform: none;
          padding: .5rem 1rem;
          margin-bottom: 1rem;
          @include tablet {
            padding: .75rem 1.5rem;
          }
        }
      }
    }

    .NewMessage {
      margin-bottom: 3rem;
      flex-direction: row;
      align-items: flex-end;
      background-color: color(highlight);
      border-radius: .75rem .75rem 0 .75rem;
      flex-shrink: 0;
      width: 100%;
      > textarea {
        height: 6rem;
        flex-grow: 1;
        font-size: 1rem;
        font-family: Montserrat, sans-serif;
        padding: .5rem;
        resize: none;
      }
      > button {
        width: 2rem;
        height: 2rem;
        background-color: color(secondary);
        justify-content: center;
        align-items: center;
        &:disabled {
          background-color: color(border);
          cursor: default;
        }
        > img {
          width: 1.5rem;
        }
      }
    }
  }
}
