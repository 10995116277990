@import "styles/variables";
@import "styles/mixins";

.SeanceDisplay {
  margin-top: -5rem;
  .SeanceMessages {
    margin-left: 1rem;
    margin-bottom: .5rem;
    .message-button {
      align-self: flex-start;
      position: relative;
      text-align:center;
      font-weight: bold;
      img {
        width: 2.5rem;
      }
      > span {
        position: absolute;
        width: 100%;
        height: 100%;
        padding-top: .5rem;
        color: #fff;
      }
    }
  }

  > header {
    position: relative;
    > button {
      @include button;
      position: absolute;
      color: #fff;
      left: 1rem;
      top: 6rem;
      padding-left: .75rem;
      background-image: url(./icons/chevron-left--light.svg);
      background-position: left -.5rem center;
      background-repeat: no-repeat;
      background-size: 1.25rem;
      z-index: 1;
      @include tablet {
        left: calc((100% - #{$width-tablet}) / 2 + 1.5rem);
      }
    }
    > img {
      height: 50vh;
      width: 100%;
      object-fit: cover;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    > h1 {
      position: absolute;
      top: 0;
      left: 0;
      padding: 4rem 1.5rem 0;
      height: 100%;
      width: 100%;
      text-align: center;
      background-image: linear-gradient(to left top, #{transparentize(#00ff83, .6)}, #{transparentize(#0b7a75, .4)});
      color: #fff;
      align-items: center;
      justify-content: center;
      text-shadow: 0 0 .5rem color(secondary);
      font-size: 2.25rem;
      line-height: 2.5rem;
      > small {
        line-height: 1.2rem;
        margin-top: .5rem;
      }
    }
  }
  > aside {
    z-index: 1;
    border-left: 1.25rem solid color(text);
    padding: 1.5rem 1.5rem .5rem 1rem;
    width: calc(100% - 3rem);
    margin-top: -3rem;
    background-color: color(background);
    @include tablet {
      width: $width-tablet;
      margin: -3rem auto 1rem;
      align-items: flex-start;
    }
    > .seance-date {
			flex-direction: row;
			align-items: center;
			margin-bottom: 1rem;
			justify-content: space-between;
			> time {
				font-weight: 700;
				text-transform: capitalize;
				font-size: 1.2rem;
				line-height: 1.6rem;
        > span {
          & + span {
            font-weight: 500;
          }
        }
			}
			> a {
				margin-left: 1rem;
				flex-direction: row;
				align-items: center;
				font-weight: 500;
				text-decoration: underline;
				img {
					width: 2rem;
					margin-left: .25rem;
				}
			}
		}
    > .MsgPage {
      padding: 0;
      flex-direction: row;
      justify-content: flex-start;
      .loader {
        margin: 0 1rem 0 0;
      }
    }
    > section {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      > strong {
        font-weight: 500;
      }
      > a {
        font-weight: 500;
        flex-direction: row;
        align-items: center;
        &::after {
          content: '';
          background-image: url(./icons/view.svg);
          width: 1.5rem;
          height: 1.5rem;
          margin-left: .5rem;
        }
      }
    }
    .booking-button {
      @include button-primary;
      padding: .75rem 1.5rem;
      margin-bottom: 1rem;
    }
    .unBooking-button {
      @include button-warning;
      padding: .75rem 1.5rem;
      margin-bottom: 1rem;
    }
    .cannot-book-button {
      @include button-disabled;
      padding: .75rem 1.5rem;
      margin-bottom: 1rem;
    }
    .watcher-book-link {
      text-decoration: underline;
      color: color(text);
    }
    .seance-video-link {
      text-decoration: underline;
      color: color(text);
    }
  }
  > section {
    padding: 1.5rem;
    align-items: flex-start;
    @include tablet {
      width: $width-tablet;
      margin: 0 auto;
    }
    > h2 {
      text-transform: uppercase;
      margin-bottom: 1rem;
      @include heavy-underline;
    }
    .CoachInfos {
      & + .CoachInfos {
        margin-top: 1.5rem;
      }
    }
  }
}

.PlaceInfos {
  position: relative;
  > header {
    > img {
      width: 100%;
    }
  }
  > section {
    margin-top: -3rem;
    background-color: color(background);
    margin-right: 3rem;
    border-left: 1.25rem solid color(text);
    padding: 1rem 2.5rem 1rem 1rem;
    position: relative;
    background-image: url(./icons/chevron-right.svg);
    background-size: 2rem;
    background-repeat: no-repeat;
    background-position: center right .5rem;
    > h3 {
      margin-bottom: .5rem;
    }
    > a {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.CoachInfos {
  width: 100%;
  align-items: center;
  position: relative;
  @include tablet {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  > img {
    $img-size: 6rem;
    width: $img-size;
    height: $img-size;
    border-radius: $img-size;
    margin-bottom: .5rem;
    object-fit: cover;
    @include tablet {
      margin-bottom: 0;
    }
  }
  > div {
    @include tablet {
      margin-left: 2rem;
    }
    h3 {
      text-align: center;
      text-transform: capitalize;
      margin-bottom: 1rem;
      @include tablet {
        text-align: left;
      }
    }
    span {
      @include button-secondary;
      padding: .75rem 1.5rem;
    }
  }
  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
