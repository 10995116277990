@import "./styles/variables";
@import "./styles/mixins";

.SignIn {
  margin-top: -5rem;
  padding: 8rem 0 2rem;
  position: relative;
  background-image: url(./images/hero.jpg);
  background-size: cover;
  background-position: center;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
  }
  > h1 {
    padding: 0 1.5rem 2rem;
    color: #fff;
    text-shadow: 0 0 .5rem color(secondary);
    position: relative;
    @include tablet {
      width: $width-tablet;
      margin: 0 auto;
    }
  }
  > form {
    padding: 1.5rem;
    margin: 0 1.5rem;
    background-color: color(background);
    position: relative;
    @include tablet {
      width: calc(#{$width-tablet} - 3rem);
      margin: 0 auto;
    }
    label:not(.has-checkbox) {
      margin-bottom: 1rem;
    }
    .fb-signin-button {
      @include button;
      background-color: color(fb);
      color: #fff;
      padding: .75rem;
    }
    .submit-user-signin {
      @include button-secondary;
      padding: .75rem;
      margin-top: 2rem;
    }
    .pwd-forgot {
      height: 2rem;
      justify-content: center;
      margin-top: -3rem;
      margin-left: auto;
      z-index: 10;
    }
    .signup-user-link {
      align-self: center;
      margin-top: 1rem;
    }
  }

  .form-error {
    padding-top: 1rem;
    
    span {
      flex-direction: row;
      justify-content: center;
    }
    a {
      font-weight: bold;
    }
  }
}