@import "./styles/variables";
@import "./styles/mixins";

.MedicalCheck {
	padding: 2rem 0;
	> h1 {
		padding: 0 1.5rem;
		margin-bottom: 1rem;
		@include tablet {
			width: $width-tablet;
			margin: 0 auto 1rem;
		}
		@include desktop {
			width: $width-desktop;
		}
	}
	form {
		@include tablet {
			width: $width-tablet;
			margin: 0 auto;
		}
		@include desktop {
			width: $width-desktop;
		}
		> div {
			margin-bottom: 1rem;
			padding: 0 1.5rem;
			@include desktop {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}
			> p {
				margin-bottom: .25rem;
			}
			> div {
				flex-direction: row;
				@include desktop {
					margin-left: 2rem;
				}
				> label {
					flex-direction: row;
					&:first-of-type {
						margin-right: 1.5rem;
					}
					span {
						font-weight: 500;
						margin-left: .25rem;
					}
				}
			}
		}
		> input[type="submit"] {
			@include button-primary;
			padding: .75rem 1.5rem;
			margin: 1.5rem 1.5rem 0;
			@include tablet {
				align-self: center;
			}
			@include desktop {
				align-self: flex-end;
			}
			&:disabled {
				@include button-disabled;
			}
		}
	}
}