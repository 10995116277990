@import "./styles/variables";
@import "./styles/mixins";

.Planning {
	width: 100%;
	> .SeanceList {
		@include tablet {
			width: calc(#{$width-tablet} - 3rem);
			margin: 0 auto;
		}
	}
}

.SwitchTools {
	background-color: color(highlight);
	> section {
		padding: 1rem 1.5rem;
		> p {
			text-align: center;
			line-height: 1rem;
			font-size: .9rem;
		}
		> button {
			margin: .5rem auto 0;
		}
	}
}

.planningActions {
  flex-direction: row;
  margin: auto;
}

.showSeanceFilters {
	@include button-primary;
	width: 5rem;
}
.mapLink {
  margin-left: 1rem;
  svg {
	width: 1.5rem;
	height: 1.5rem;
  }
}

.SeanceFilters {
	margin: auto;
}

.SeanceTypeFilter {
	> input {
		height: 2rem;
	}
	flex-direction: row;
	>label {
		font-size: 1rem;
		padding: 1rem 0.5rem 0 0;
	}
	> button {
		padding: 1rem;
	}
}
.SeanceCoachFilter {
	flex-direction: row;
	padding: 0 1rem 0.5rem 0;
	>label {
		font-size: 1rem;
		padding-right: 0.5rem;
	}
}
.SeanceCourseFilter {
  flex-direction: row;
  padding: 0.5rem 1rem 0.5rem 0;
  >label {
	font-size: 1rem;
	padding-right: 0.8rem;
  }
}
.SeancePlaceFilter {
	flex-direction: row;
	>label {
		font-size: 1rem;
		padding-right: 1.5rem;
	}
	>input {
		 border-bottom: 2px solid red;
	}
}

.showSeanceType {
	color: color(primary);
}


.removeSelectedCoachBtn {
	&:hover {
		border: 2px solid red;
		border-radius: 1rem;
	}
	flex-direction: row;
	font-size: 1rem;
	>img {
		margin-left: 0.3rem;
		width: 1.5rem;
		height: 1.5rem;
	}
	max-width: 15rem;
}

.removeSelectedPlaceBtn {
		&:hover {
		border: 2px solid red;
		border-radius: 1rem;
	}
	flex-direction: row;
	font-size: 1rem;
	>img {
		margin-left: 0.3rem;
		width: 1.5rem;
		height: 1.5rem;
	}
	max-width: 25rem;
}

.removeCoachImg {
}
