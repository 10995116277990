@import "styles/variables";
@import "styles/mixins";

.MessageModal {

  .messages-modal {
    > section {
      > div {
        overflow-y: auto;
        > p {
          padding: 0 0 1rem;
          text-align: center;
        }
      }
    }
  }

  .MessageList {
    flex-shrink: 0;
    > li {
      margin-bottom: 1rem;
      flex-shrink: 0;
      &.mine {
        align-items: flex-end;
        > article {
          > section {
            background-color: color(secondary);
            border-radius: .75rem .75rem 0 .75rem;
            color: #fff;
            align-items: flex-end;
            > time {
              color: #fff;
              opacity: .7;
            }
          }
        }
      }
      &.coach, &.admin {
        > article > section > span {
					color: color(secondary);
				}
      }
			&.admin {
				> article > section > span {
					color: color(warning);
				}
			}

      > article {
        max-width: 85%;
        > section {
          background-color: color(border--lighter);
          padding: 1rem 1rem.5rem 1rem;
          border-radius: .75rem .75rem .75rem 0;
          align-self: flex-start;
					> span {
						font-weight: 700;
						display: inline;
						em {
							display: inline;
							font-weight: 300;
							color: color(text);
							margin-left: .5rem;
							font-size: .9rem;
						}
					}
          > p {
            white-space: pre-wrap;
          }
          > time {
            font-size: .9rem;
            color: color(default);
            align-self: flex-end;
          }
        }
      }
    }
  }

  .NewMessage {
    flex-direction: row;
    align-items: flex-end;
    background-color: color(highlight);
    border-radius: .75rem .75rem 0 .75rem;
    flex-shrink: 0;
    > textarea {
      flex-grow: 1;
      font-size: 1rem;
      font-family: Montserrat, sans-serif;
      padding: .5rem;
      resize: none;
    }
    > button, > span {
      width: 2rem;
      height: 2rem;
      background-color: color(secondary);
      justify-content: center;
      align-items: center;
      &:disabled {
        background-color: color(border);
        cursor: default;
      }
      > img {
        width: 1.5rem;
      }
    }
		> span {
			font-weight: 700;
			color: #fff;
			background-color: color(border);
		}
  }
}
