@import "./styles/variables";
@import "./styles/mixins";

.SeanceList {
  padding: 1.5rem;
  @include tablet {
    padding: 1.5rem 3rem;
  }
  &.past {
    > li {
      &.seance-list-day {
        background-color: color(border--lighter);
      }
      .SeancePreview {
        > header {
          img {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
          }
        }
      }
    }
  }
  > li {
    padding: 1rem 0;
    flex-shrink: 0;
    &:not(.seance-list-day) + li:not(.seance-list-day) {
      border-top: 1px solid color(border);
    }
    &.seance-list-day {
      text-transform: capitalize;
      padding: .5rem 1.5rem;
      background-color: color(highlight);
      margin: 0 -1.5rem;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      strong {
        font-weight: 500;
      }
    }
  }
}

.SeancePreview {
  flex-direction: row;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  &.has-action {
    .link-to-seance {
      width: calc(100% - 3rem);
    }
  }
  &.past-seance {
    opacity: .5;
  }
  > header {
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    &.multiple-coaches {
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			img {
				$img-w: 2.75rem;
				width: $img-w;
				height: $img-w;
				border-radius: $img-w;
				&:nth-of-type(2) {
					margin-top: calc(#{$img-w} / 2);
					margin-left: calc(#{$img-w} / -2);
					border: .2rem solid color(background);
					box-sizing: content-box;
				}
			}
		}
    img {
			$img-w: 4.5rem;
      width: $img-w;
      height: $img-w;
      border-radius: $img-w;
      flex-shrink: 0;
      object-fit: cover;
    }
  }
  > section {
    > div {
      > span {
        @include tablet {
          width: 8rem;
          margin-right: 1rem;
        }
      }
      &:first-of-type {
        @include tablet {
          flex-direction: row;
          align-items: center;
        }
      }
      &:last-of-type {
        @include tablet {
          flex-direction: row-reverse;
          align-items: flex-start;
          strong {
            margin-right: auto;
          }
        }
        strong {
          font-weight: 500;
        }
        span {
          text-transform: capitalize;
          em {
            text-transform: none;
          }
        }
      }
    }
  }

  .add-seance-calendar {
    width: 3rem;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: auto;
    img {
      width: 2rem;
    }
  }
  .link-to-seance {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  
  .EventPreview {
    margin-left: 0;
    text-align: center;
    padding: 0 2rem;
    > span {
      font-weight: 500;
      border-radius: 0.25rem;
      color: white;
      background-color: color(header);
      @include mobile {
        padding: 0 1rem;
      }
    }
  }
  .PricePreview {
    font-weight: 600;
    border-radius: 0.25rem;
    padding: .5rem .5rem;
    margin-left: 1rem;
    color: color(secondary);
  }
  .StagePreview {
    font-weight: 600;
    border-radius: 0.25rem;
    padding: .5rem .5rem;
    margin-left: 1rem;
    color: color(secondary);
  }
}
