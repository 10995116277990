@import "./styles/variables";
@import "./styles/mixins";

.LiveMoreInfo {

	@include desktop {
		position: absolute;
	}
	p {
		margin: 1rem;
	}

	span {
		margin: 1rem;
	}
}
