@import "./styles/variables";
@import "./styles/mixins";

.DaySelector {
	flex-direction: row;
	align-items: center;
	> li {
		flex-grow: 1;
		flex-basis: 100%;
		align-items: center;
		> button {
			min-width: 2rem;
			width: 100%;
			align-items: center;
			&:disabled {
				pointer-events: none;
				opacity: .3;
			}
			img {
				width: 2rem;
			}
		}
		> time {
			align-items: center;
			border-bottom: 5px solid transparent;
			padding: 0 0 .25rem;
			width: 100%;
			cursor: pointer;
			@include tablet {
				padding-top: .5rem;
			}
			&.active {
				border-color: color(secondary);
			}
			&.disabled {
				opacity: .3;
				pointer-events: none;
				cursor: default;
			}
			strong {
				display: inline;
				span {
					display: none;
					@include tablet {
						display: inline;
					}
				}
			}
			.pastDate{
				opacity: .3;
			}
		}
	}
}

.DaySelector-month {
	padding-left: 15%;
	text-transform: capitalize;
	font-weight: 500;
	@include tablet {
		display: none;
	}
}