@import "./styles/variables";
@import "./styles/mixins";

.PasswordChange {
	margin-top: -5rem;
	padding: 8rem 0 2rem;
	position: relative;
	background-image: url(./images/hero.jpg);
	background-size: cover;
	background-position: center;
	&::before {
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
	}
	> h1 {
		padding: 0 1.5rem 2rem;
		color: #fff;
		text-shadow: 0 0 .5rem color(secondary);
		position: relative;
		@include tablet {
			width: $width-tablet;
			margin: 0 auto;
		}
	}
	> .MsgPage {
		margin: 0 1.5rem;
		background-color: #fff;
		position: relative;
		padding: 1.5rem;
		flex-grow: unset;
		@include tablet {
			width: calc(#{$width-tablet} - 3rem);
			margin: 0 auto;
		}
		> p {
			text-align: center;
		}
		> button {
			@include button-secondary;
			padding: .75rem 1.5rem;
		}
	}
	form {
		padding: 1.5rem;
		margin: 0 1.5rem;
		background-color: color(background);
		position: relative;
		@include tablet {
			width: calc(#{$width-tablet} - 3rem);
			margin: 0 auto;
		}
		.submit-password-button {
			@include button-secondary;
			color: #fff;
			padding: .75rem;
			margin-top: 2rem;
		}
	}
}