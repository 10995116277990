@import "./styles/variables";
@import "./styles/mixins";

.CoachSelect {
	ul {
		flex-direction: row;
		padding: 1rem 1rem;
		> li {
			flex-direction: column !important;
			@include button;
			padding: .2rem;
			color: color(text);
			@include tablet {
				flex-basis: auto;
			}
			&.active {
				color: color(secondary);
			}
			span {
				margin: .5rem;
			}
			img {
				height: auto;
				width: 4rem;
				object-fit: cover;
				border-radius: 2rem;
			}
		}
	}
}

.Score {
	ul {
		flex-direction: row;
		justify-content: center;
		padding: 1rem 0;
		li {
			@include button;
			padding: .5rem;
			flex-basis: 100%;
			color: color(text);
			text-align: center;
			line-height: 1rem;
			@include tablet {
				flex-basis: auto;
			}
			& + li {
				margin-left: .5rem;
			}
			&.active {
				color: color(secondary);
			}
			&.onError {
				color: red;
			}
		}
	}
}

.Improvment {
	ul {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		li {
			padding: .25rem .5rem;
			margin: 0 .25rem .5rem;
			font-weight: 500;
			border-radius: .25rem;
			color: color(text);
			border: 1px solid color(border);
			@include tablet {
				cursor: pointer;
			}
			&:hover {
				color: color(secondary);
			}
			&.active {
				background-color: color(secondary);
				color: #fff;
				border-color: color(secondary);
			}
		}
	}
	> p {
		margin-bottom: 1rem;
	}
}

.Modal > .booking-feedback {
	> section {
		> footer {
			margin-top: .5rem;
		}
	}
}

.FeedbackForm {
	flex-shrink: 0;

	.InlineText {
		display: inline;
	}
	> h2 {
		text-align: center;
		text-transform: uppercase;
	}

	> strong {
		text-align: center;
	}
	input[type="submit"] {
		@include button-primary;
		padding: .75rem 1.5rem;
		&.disabled {
			@include button-disabled;
		}
	}
	.cancel-feedback-button {
		@include button;
		padding: .75rem 1.5rem;
	}
	textarea {
		border-bottom: 2px solid color(border);
		font-family: Montserrat, sans-serif;
		background-color: color(border--lighter);
		resize: none;
		margin: .5rem 0 0;
		height: 5rem;
		padding: .5rem;
		font-size: 1rem;
	}
}
