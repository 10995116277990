@import "./styles/variables";
@import "./styles/mixins";

.SearchTeacherPage {
  .SearchTools {
    position: sticky;
    top: 5rem;
    z-index: 9;
    @include desktop {
      top: 0;
    }
  }
}
