@import "./styles/variables";
@import "./styles/mixins";

.UnbookModal {
	section {
		p {
			margin-bottom: 1rem;
			strong {
				margin: .5rem 0;
			}
		}
		.error {
			font-weight: 500;
			color: color(warning);
		}
	}

	&.UnbookModal_Failure {
		.MsgPage {
			padding: 0;
		}
	}
}