@import "variables";
@import "mixins";

:root {
  @each $name, $color in $colors {
    --color-#{$name}: #{$color};
  }
  @include dark-mode {
    --color-background: #333;
    --color-text: #ddd;
    --color-highlight: #667579;
    --color-border--lighter: #444;
  }
}

html {
  background-color: color(background);
}

body {
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  line-height: 1.4rem;
  color: color(text);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  &.stop-scroll {
    overflow: hidden;
  }
}

#root {
  flex-grow: 1;
  > div {
    flex-grow: 1;
  }
}

main {
  flex-grow: 1;
  padding-top: 5rem;
  > div {
    flex-grow: 1;
  }
}

a {
  color: inherit;
}

button {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
}

h1 {
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: uppercase;
}

h2 {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 700;
}

h3 {
  font-size: 1.2rem;
  line-height: 1.4rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.1rem;
}

h5 {
  font-size: .9rem;
  line-height: 1rem;
}

h6 {
  font-size: .8rem;
  line-height: 1rem;
}

.separator {
  position: relative;
  text-align: center;
  margin: 1rem 0;
  height: 1rem;
  align-items: center;
  &:after {
    content: attr(data-label);
    position: absolute;
    background-color: color(background);
    padding: 0 .5rem;
    text-transform: uppercase;
    font-size: .9rem;
    letter-spacing: .05rem;
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 1rem;
    width: calc(100% - 2rem);
    border-bottom: 1px solid color(border);
  }
}

div.richText {
  * {
    text-align: left !important;
    line-height: 1.3rem !important;
    font-size: 1rem !important;
    color: color(text) !important;
    font-family: Montserrat, sans-serif !important;
  }
  strong, em, span {
    display: inline;
  }
  div, p {
    display: block;
  }
  > p {
    margin-bottom: .5rem;
  }
  > ul {
    padding-left: .75rem;
    li {
      margin-bottom: .3rem;
      flex-direction: row;
      display: block;
      text-indent: -.75rem;
      &:before {
        content: "-";
        margin-right: .5rem;
      }
    }
  }
  &.highlight {
    background-color: color(border);
    border-radius: .5rem;
    padding: .75rem;
  }
}

.cookies-banner {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: color(secondary);
  width: 100%;
  padding: .75rem 1rem;
  align-items: center;
  p {
    display: inline;
    font-size: .9rem;
    color: #fff;
    text-align: center;
    margin-bottom: .5rem;
    a {
      display: inline;
      font-size: .9rem;
      text-decoration: underline;
    }
  }
  button {
    @include button-primary;
    padding: .5rem 1rem;
  }
}

@keyframes scaleout {
  0% {
    transform: scale(0);
  } 100% {
      transform: scale(1);
      opacity: 0;
    }
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -.75rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
