/* FONTS */

$rem: 16px;


/* COLORS */

$colors: (
  primary: #00EA6F,
  primary--light: lighten(#00EA6F, 15),
  secondary: #168376,
  secondary--dark: darken(#168376, 10),
contrast: #FF8E72,
contrast--dark: darken(#FF8E72, 10),
  default: #747474,
  highlight: #f5f9e9,
cardio: #ef476f,
renfo: #28afb0,
zen: #ffd166,
  warning: #9A031E,
  warning--dark: darken(#9A031E, 10),
  cool: #5fce9c,
  background: #fff,
  footer: #424B54,
  footer--dark: darken(#424B54, 5),
  text: #555555,
  border: #b8b8b8,
  border--light: lighten(#b8b8b8, 20),
  border--lighter: lighten(#b8b8b8, 25),
  header: #424B54,
  fb: #1877F2,
  ig: #c13584,
  tw: #1da1f2,
  yt: #ff0000,
  li: #2867B2,
	rank-1: #FFC800,
	rank-2: #D72638,
	rank-3: #031927,
);


/* Z-INDEX */

$z-modal: 1000;


/* SCREEN WIDTH */

$width-mobile: 414px;
$width-tablet: 768px;
$width-desktop: 1280px;
