@import "./styles/variables";
@import "./styles/mixins";

.GMapsPlaceDisplay {
	background-color: color(background);
	position: absolute;
	width: 100%;
	bottom: 0;
	z-index: $z-modal + 1;
	transform: translateY(calc(100% - 4rem));
	transition: all .3s .1s;
	@include tablet {
		width: calc(#{$width-mobile} - 6rem);
		height: calc(100% - 5rem);
		transform: translateY(0) translateX(-100%);
		z-index: 0;
	}
	@include desktop {
		width: $width-mobile;
	}
	&.has-image {
		transform: translateY(calc(100% - 10rem));
		@include tablet {
			transform: translateY(0);
		}
	}
	&.reduced {
		@include tablet {
			transform: translateY(0);
		}
	}
	&.full {
		transform: translateY(0) translateX(0);
		.place-img {
			height: 10rem;
			&::after {
				background: transparent;
				background-position: top -1rem center;
			}
			@include tablet {
				height: 13rem;
			}
		}
		h1 {
			display: flex;
			white-space: normal;
			text-overflow: initial;
		}
	}
	.place-img {
		height: 5rem;
		width: 100%;
		transition: all .3s;
		position: relative;
		flex-shrink: 0;
		@include tablet {
			height: 13rem;
		}
		&::after {
			content: '';
			background-color: rgba(0, 0, 0, .3);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: height .3s;
			background-image: url(./icons/chevron-up--light.svg);
			background-repeat: no-repeat;
			background-position: top .5rem center;
			background-size: 2rem;
			@include tablet {
				background: transparent;
				background-position: top -1rem center;
			}
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	> section {
		flex-shrink: 0;
		@include tablet {
			@include has-separator;
		}
		h1 {
			padding: 1.5rem;
			font-size: 1.4rem;
			line-height: 1.6rem;
			white-space: nowrap;
			text-overflow: ellipsis;
			display: block;
			overflow: hidden;
			transition: all .3s;
		}
		.place-location {
			padding: .25rem 1.5rem 1rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			> span {
				margin-left: 1rem;
				flex-direction: row;
				align-items: center;
				opacity: .6;
				img {
					width: 1rem;
					opacity: .6;
				}
			}
		}
		> a {
			@include button-primary;
			padding: .75rem;
			margin: 0 1.5rem 1.5rem;
		}
	}
	> ul {
		overflow-y: auto;
		padding: 1rem 0;
		display: none;
		@include tablet {
			display: flex;
		}
		> li {
			flex-shrink: 0;
			& + li {
				border-top: .5rem solid color(default);
			}
			article {
				> div {
					display: none;
				}
			}
		}
	}
}