@import "./styles/variables";
@import "./styles/mixins";

.planSeanceSearch {
	text-align: center;
	@include button-secondary;
	padding: .75rem 1.5rem;
	margin:auto;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.CurrentPlan {
	padding: 1rem 0;
	@include tablet {
		width: $width-tablet;
		margin: 0 auto;
		flex-direction: row;
		padding: 2rem 0;
	}
	> article {
		padding: 1.5rem;
		@include tablet {
			width: 50%;
		}
		h2 {
			@include heavy-underline;
			text-transform: uppercase;
			margin-bottom: .5rem;
		}
		> section {
			time {
				font-size: .9rem;
				margin-bottom: .5rem;
			}
			strong {
				font-size: 1.2rem;
				line-height: 1.4rem;
			}
			p {
				margin-top: .25rem;
			}
			button {
				@include button-secondary;
				padding: .75rem 1.5rem;
				margin-top: 1rem;
			}
		}
	}

	> a{
		@include button-secondary;
		padding: .75rem 1.5rem;
		margin-top: 1rem;
	}
}

.ManagePlan {
	background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
	padding: 4rem 0 2rem;
	margin-top: -5rem;
	> h1 {
		color: #fff;
		padding: 2rem 1.5rem 0;
		@include tablet {
			margin: 0 auto;
			width: calc(#{$width-tablet} - 3rem);
		}
	}
}

.PromoModal {
	> section {
		> form {
			.error {
				margin-top: .5rem;
				color: color(warning);
			}
		}
	}
}

.SelectPlan {
	background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
	padding: 4rem 0 2rem;
	margin-top: -5rem;
	> h1 {
		color: #fff;
		padding: 2rem 1.5rem 0;
		@include tablet {
			margin: 0 auto;
			width: calc(#{$width-tablet} - 3rem);
		}
	}
}

.OfferConfirmationModal {
	.OfferPreview {
		margin: 0 0 1rem;
		> header {
			padding: 1rem 0;
			> span {
				display: none;
			}
		}
		> section {
			align-items: flex-end;
			p {
				> * {
					text-align: right;
				}
			}
		}
	}
}

.Subscribe {
	flex-grow: 1;
	> .PromoCodeForm {
		position: relative;
		padding: 1.5rem;
		background-color: #fff;
		@include tablet {
			padding: 2rem calc((100% - #{$width-tablet}) / 2 + 3rem);
		}
	}
	.promo-code-confirm {
		z-index: 1;
		@include tablet {
			padding: 2rem calc((100% - #{$width-tablet}) / 2 + 3rem);
		}
	}
	> .SelectPlan {
		flex-grow: 1;
	}
}

.PromoCodeForm {
	> div {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		.FormField {
			width: calc(100% - 7.5rem);
		}
		input[type="submit"] {
			@include button-secondary;
			padding: .75rem;
			margin-left: 1rem;
		}
	}
	.promo-code-error {
		color: color(warning);
	}
}

.promo-code-confirm {
	background-color: color(highlight);
	padding: 1rem;
	flex-direction: row;
	align-items: center;
	p {
		display: inline;
		> * {
			display: inline;
		}
	}
	> button {
		margin-left: auto;
		img {
			width: 1.5rem;
		}
	}
}
