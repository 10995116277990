@import "./styles/variables";
@import "./styles/mixins";

.Coaching {
	margin-top: -5rem;
	> header {
		padding: 6.5rem 0 1.5rem;
		width: 100%;
		background-color: color(secondary);
		color: #fff;
		@include tablet {
			padding: 6.5rem 1.5rem 2rem;
		}
		@include desktop {
			flex-direction: row;
			padding: 7.5rem calc((100% - #{$width-desktop}) / 2 + 1.5rem) 2rem;
		}
		> h1 {
			text-align: center;
			margin-bottom: 1rem;
			@include tablet {
				font-size: 2rem;
				line-height: 2.2rem;
				margin-bottom: 2rem;
			}
			@include desktop {
				margin-bottom: 0;
			}
		}
	}
}

.CoachingMenu {
	width: fit-content;
	@include tab-bar;
	@include tablet {
		width: calc(#{$width-tablet} - 3rem);
		margin: 0 auto;
	}
	@include desktop {
		margin: 0 0 0 auto;
	}
}

.CoachingIncomingList {
	padding : 1rem 1.5rem;
	@include tablet {
		width: $width-tablet;
		margin: 0 auto;
	}
	h4 {
		margin-bottom: 1.5rem;
	}
}
.SubmitButton {
	@include button-secondary;
	padding: .75rem;
	margin-top: 2rem;
}

.CoachingParams {
	padding : 1rem 1.5rem;
	@include tablet {
		width: $width-tablet;
		margin: 0 auto;
	}
}

.CoachingBookingTrial {
	color: color(primary);
}

.CoachingIncomingPreview {
	position: relative;
	&.past {
		opacity: .4;
	}
	> h3 {
		margin-bottom: .25rem;
	}
	> a {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		&:hover {
			& ~ h3 {
				text-decoration: underline;
			}
		}
	}
}

.CoachingFeedback {
	padding : 1rem 1.5rem;
	@include tablet {
		width: $width-tablet;
		margin: 0 auto;
	}
	> aside {
		display: grid;
		@include tablet {
			grid-template-columns: 20% 80%;
		}
		> div:first-child {
			text-align: center;
			justify-content: center;
			> strong {
				font-weight: 900;
				font-size: 2rem;
				line-height: 2.5rem;
			}
		}
		> div:last-child {
			@include tablet {
				text-align: right;
			}
			> label {
				display: inline;
			}
			> ul {
				> li {
					margin-bottom: 1rem;
					display: grid;
					@include tablet {
						grid-template-columns: 20% 80%;
						grid-gap: 1rem;
					}
					> button {
						position: relative;
						width: 100%;
						border: 1px solid color(border);
						border-radius: .25rem;
						height: 1.5rem;
						&.selected {
							border-color: color(primary);
						}
						&.empty {
							opacity: .5;
							pointer-events: none;
						}
						> span {
							height: 100%;
							position: absolute;
							min-width: 1.5rem;
							padding-right: .25rem;
							background-color: color(primary);
							border-radius: .25rem;
							justify-content: center;
							text-align: right;
						}
					}
				}
			}
		}
	}
}

.CoachingFeedbackPreview {
	display: grid;
	grid-template-columns: 80% 20%;
	grid-column-gap: 1rem;
	> a {
		grid-area: 1 / 1 / 2 / 2;
		padding: .5rem 1rem 0;
		&:hover {
			& > span {
				text-decoration: underline;
			}
		}
	}
	> p {
		grid-area: 2 / 1 / 3 / 2;
		background-color: color(highlight);
		padding: .5rem 1rem .5rem 1rem;
	}
	> div {
		grid-area: 1 / 2 / 3 / 3;
		text-align: center;
		justify-content: center;
	}
}

.CoachingBonusPreview {
	display: grid;
	grid-template-columns: 80% 20%;
	grid-column-gap: 1rem;
	> p {
		grid-area: 1 / 1 / 2 / 2;
		background-color: color(highlight);
		padding: 1rem 1rem .5rem;
	}
	> a {
		grid-area: 2 / 1 / 3 / 2;
		padding: .5rem 1rem 0;
	}
	> strong {
		grid-area: 1 / 2 / 3 / 3;
		text-align: center;
		justify-content: center;
	}
}

.CoachingBonusLabel {
	text-align: center;
	margin-top: 1rem;
}

.CoachingBonusTable {
	margin-left: 3rem;
	margin-right: 3rem;
	margin-top: 1rem;
	td {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		text-align: center;
	};
	tr:nth-child(even){background-color: #f2f2f2; color: black}
	th {
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;
	  background-color: color(secondary);

	  color: white;
		text-align: center;
	}
}

.CoachingIncomingOne {
	.coach_practical {
		padding: 0 0 0.3rem 0;
	}
	.practical {
		padding:  0.3rem 0 0 0;
	}
	.not-yours {
		background-color: color(warning);
		color: #fff;
		padding: 1rem 1.5rem;
		@include tablet {
			padding: 1rem calc((100% - #{$width-tablet}) / 2 + 1.5rem);
		}
	}
	> header {
		padding: 2rem 1.5rem;
		@include tablet {
			padding: 2rem calc((100% - #{$width-tablet}) / 2 + 1.5rem);
		}
		h2 {
			text-transform: uppercase;
			text-align: center;
			margin-bottom: .5rem;
		}
		span {
			text-align: center;
			margin-bottom: 1rem;
			&:first-of-type {
				flex-direction: row;
				justify-content: center;
				flex-wrap: wrap;
			}
			a {
				margin-left: 1rem;
				flex-direction: row;
				align-items: center;
				font-weight: 500;
				text-decoration: underline;
				img {
					width: 1.5rem;
					margin-left: .25rem;
				}
			}
		}
	}
	.CoachingBookingList {
		@include tablet {
			padding: 0 calc((100% - #{$width-tablet}) / 2 + 1.5rem);
		}
	}
}

.CoachingBookingList {
	padding: 0 1.5rem;
	> li {
		padding: 1rem 0;
		& + li {
			border-top: 1px solid color(border);
		}
	}
}

.CoachingBooking {
	@include tablet {
		flex-direction: row;
		justify-content: space-between;
	}
	header {
		margin-bottom: 1rem;
		flex-direction: row;
		align-items: center;
		@include tablet {
			text-align: left;
			margin-right: 1rem;
			margin-bottom: 0;
		}
		> img {
			$img_w: 3.5rem;
			width: $img_w;
			height: $img_w;
			border-radius: $img_w;
			margin-right: 1rem;
			object-fit: cover;
		}
		.RankOne {
			border: 5px solid color(rank-1);
		}
		.RankTwo {
			border: 5px solid color(rank-2);
		}
		.RankThird {
			border: 5px solid color(rank-3);
		}
		h3 {
			margin-bottom: .25rem;
			flex-direction: row;
			align-items: center;
			flex-wrap: wrap-reverse;
			> strong {
				margin-right: .5rem;
				font-size: 1.2rem;
			}
			> span {
				font-weight: 300;
				font-size: .9rem;
				padding: 0 .25rem;
				color: #fff;
				border-radius: .25rem;
				background-color: color(secondary);
			}
		}
		a {
			text-decoration: underline;
		}
	}
	footer {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		> span {
			font-weight: 500;
		}
		.presence-selector {
			flex-direction: row;
			margin-left: 1rem;
			> button {
				@include button-disabled;
				padding: .5rem 1rem;
				&.present {
					&.active {
						@include button-primary;
					}
				}
				&.absent {
					margin-left: .5rem;
					&.active {
						@include button-warning;
					}
				}
			}
		}
	}
}

.CoachingMessageForm {
	padding: 0 1.5rem;
	> textarea {
		border: 1px solid color(border);
		padding: .5rem;
		font-size: 1rem;
		height: 5rem;
		margin-bottom: 1rem;
	}
	> div {
		> button {
			padding: .75rem 1.5rem;
			&.coaching-message-send {
				@include button-secondary;
			}
			&.coaching-message-cancel {
				@include button;
			}
		}
	}
}

.AttendeesInteractions {
	@include tablet {
		padding: 0 calc((100% - #{$width-tablet}) / 2 + 1.5rem);
	}
	justify-content: space-around;
	> button {
		@include button-primary;
		margin: .5rem 0 .5rem 0;
		padding: .5rem 1rem .5rem 1rem;
	}
	.message-bloc {
		flex-direction: row;
		align-items: center;
		.message-button {
			align-self: flex-start;
			position: relative;
			text-align: center;
			font-weight: bold;
			img {
				width: 2.5rem;
			}
			> span {
				position: absolute;
				width: 100%;
				height: 100%;
				padding-top: .5rem;
				color: #fff;
			}
		}
	}
}

.CoachingInvoices {
	flex-grow: 1;
	padding: 2rem 0;
	> ul {
		padding: 0 1.5rem;
		margin-bottom: 1rem;
		> li {
			padding: 1rem 0;
		}
	}
}

.CoachingInvoice {
	@include tablet {
		padding: 0 calc((100% - #{$width-tablet}) / 2 + 1.5rem);
	}
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	> strong {
		flex-grow: 1;
		flex-direction: row;
		justify-content: space-between;
		text-transform: capitalize;
	}
	> a {
		background-color: color(secondary);
		background-image: url(./icons/download--light.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 1.5rem;
		height: 2rem;
		width: 2rem;
		margin-left: 1rem;
		span {
			display: none;
		}
	}
}

.CoachingNewMessage {
	margin-bottom: 3rem;
	flex-direction: row;
	align-items: flex-end;
	background-color: color(highlight);
	border-radius: .75rem .75rem 0 .75rem;
	flex-shrink: 0;
	width: 100%;
	> textarea {
		height: 6rem;
		flex-grow: 1;
		font-size: 1rem;
		font-family: Montserrat, sans-serif;
		padding: .5rem;
		resize: none;
	}
	> button {
		width: 2rem;
		height: 2rem;
		background-color: color(secondary);
		justify-content: center;
		align-items: center;
		&:disabled {
			background-color: color(border);
			cursor: default;
		}
		> img {
			width: 1.5rem;
		}
	}
}
