@import "./styles/mixins";

.PaymentForm {
	form {
		.accepted-cards {
			flex-direction: row;
			margin-bottom: .5rem;
			img {
				height: 3rem;
				border: 1px solid color(border--light);
				& + img {
					margin-left: 1rem;
				}
			}
		}
		.FormField {
			margin-bottom: .5rem;
		}
		.other-infos {
			flex-direction: row;
			align-items: center;
			margin-bottom: 1.5rem;
			.FormField {
				width: 33%;
				margin-right: 1rem;
			}
			img {
				height: 2.5rem;
			}
		}
		.payment-cgv {
			flex-direction: row;
			span {
				display: inline;
				margin-left: .5rem;
				a {
					display: inline;
					font-weight: 700;
				}
			}
			&.invalid {
				color:red;
			}
		}
		.payment-cgv-disclaimer {
			display: inline;
			font-size: 1rem;
			line-height: 1.4rem;
			margin-top: 1rem;
			> strong {
				font-size: 1rem;
				font-weight: 700;
				display: inline;
			}
		}
	}
}