@import "./styles/variables";
@import "./styles/mixins";

.MainMenu {
	position: fixed;
	width: 100%;
	height: 100%;
	padding: 5rem 1.5rem 0;
	background-color: color(header);
	color: #fff;
	overflow-y: auto;
	> * {
		flex-shrink: 0;
	}
	.connectLink {
		font-size: 2.4rem;
		line-height: 2.7rem;
		font-weight: 700;
		padding-bottom: 1rem;
		&.active {
			color: color(highlight);
		}
		@include desktop {
			&:hover {
				color: color(primary);
			}
		}
	}
	> header {
		@include tablet {
			width: calc(#{$width-tablet} - 3rem);
			margin: 0 auto 1rem;
		}
		@include desktop {
			width: calc(#{$width-desktop} - 3rem);
			margin-bottom: 2rem;
		}
		button.close-menu {
			position: fixed;
			top: -.5rem;
			background-color: color(primary);
			width: 4.5rem;
			height: 4.5rem;
			align-self: flex-end;
			align-items: center;
			justify-content: center;
			border-radius: 3rem;
			> img {
				width: 2.25rem;
			}
		}
	}
	> section {
		flex-grow: 1;
		@include tablet {
			width: calc(#{$width-tablet} - 3rem);
			margin: 0 auto;
		}
		@include desktop {
			width: calc(#{$width-desktop} - 3rem);
		}
		.logo-menu {
			position: -webkit-sticky;
			position: sticky;
			bottom: -2rem;
			margin: -10rem 0 -2rem -5rem;
			z-index: -1;
			width: 25rem;
			@include tablet {
				width: 30rem;
			}
			@include desktop {
				width: 35rem;
				margin-bottom: -3rem;
			}
		}
		> div {
			margin-bottom: 2rem;
			@include tablet {
				flex-direction: row;
			}
			.main-nav {
				&::before {
					content: '';
					height: 4px;
					width: 3rem;
					background-color: color(primary);
					margin-bottom: 2rem;
					@include tablet {
						height:0;
						width:0 ;
					}
				}
				margin-bottom: 2rem;
				> ul {
					align-items: flex-start;
					> li {
						margin-bottom: 1rem;
						a, button {
							font-size: 1.8rem;
							line-height: 1.7rem;
							font-weight: 700;
							&.active {
								color: color(highlight);
							}
							@include desktop {
								&:hover {
									color: color(primary);
								}
							}
						}
					}
				}
			}
			.secondary-nav {
				&::before {
					content: '';
					height: 4px;
					width: 3rem;
					background-color: color(primary);
					margin-bottom: 2rem;
				}
				@include tablet {
					margin: 2rem 0 2rem 5rem;
				}
				> ul {
					align-items: flex-start;
					> li {
						margin-bottom: 1rem;
						a, button {
							color: #fff;
							font-size: 1.2rem;
							line-height: 1.4rem;
							font-weight: 700;
							&.active {
								color: color(highlight);
							}
							@include desktop {
								&:hover {
									color: color(primary);
								}
							}
						}
					}
				}
			}
		}
		.contact-nav {
			margin-top: 1rem;
			@include tablet {
				margin: 2rem 0 2rem 5rem;
			}
			h4 {
				font-weight: 500;
				color: transparentize(#fff, .5);
				margin-bottom: .5rem;
			}
			> ul {
				> li {
					a {
						font-weight: 700;
						padding: .25rem 0;
						margin-bottom: .25rem;
					}
				}
			}
		}
		.social-nav {
			margin-bottom: auto;
			> ul {
				flex-direction: row;
				align-items: center;
				> li {
					margin-right: .5rem;
					@include tablet {
						margin-right: 1rem;
					}
					&:last-of-type {
						margin-right: 0;
					}
					> a {
						height: 4rem;
						width: 4rem;
						align-items: center;
						justify-content: center;
						border-radius: 4rem;
						&:hover {
							&.facebook {
								background-color: color(fb);
							}
							&.linkedin {
								background-color: color(li);
							}
							&.instagram {
								background-color: color(ig);
							}
							&.twitter {
								background-color: color(tw);
							}
							&.youtube {
								background-color: color(yt);
							}
						}
						> img {
							width: 3rem;
						}
					}
				}
			}
		}
	}
	> footer {
		margin: 0 -1.5rem 0 -1.5rem;
		padding: 1.5rem;
		background-color: color(footer--dark);
		.footer-nav {
			> ul {
				align-items: center;
				@include tablet {
					flex-direction: row;
					justify-content: center;
				}
				> li {
					margin-bottom: .5rem;
					@include tablet {
						& + li {
							margin-left: 2rem;
						}
					}
					> a {
						font-size: .8rem;
						font-weight: 700;
					}
				}
			}
		}
		p {
			font-size: .8rem;
			text-align: center;
			margin-top: 1rem;
		}
	}
}