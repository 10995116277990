@import "./styles/variables";
@import "./styles/mixins";

.ImageModifiable {
	> .cropper {
		max-height: 40vh;
	}
	.ImageUpload {
		@include tablet {
			align-items: flex-start;
		}
		> em {
			margin-bottom: .5rem;
		}
		> span {
			@include button-secondary;
			padding: .75rem 1.5rem;
		}
		> input {
			visibility: hidden;
		}
	}
	.ImageDisplay {
		align-items: center;
		@include tablet {
			align-items: flex-start;
		}
		> img {
			$img-w: 10rem;
			width: $img-w;
			height: $img-w;
			border-radius: $img-w;
			object-fit: cover;
		}
		> button {
			@include button-warning;
			padding: .75rem 1.5rem;
			margin-top: .5rem;
		}
	}
	> footer {
		@include tablet {
			flex-direction: row-reverse;
			margin-top: 1rem;
		}
		.button-save {
			@include button-secondary;
			padding: .75rem 1.5rem;
		}
		.button-cancel {
			@include button;
			padding: .75rem 1.5rem;
			margin-top: .5rem;
			@include tablet {
				margin: 0 1rem 0 0;
			}
		}
	}
}