@import './styles/variables';
@import './styles/mixins';

.attendees-modal {
  $img_w: 3.5rem;
  ul {
    overflow-y: auto;
    @include tablet {
      flex-direction: row;
      flex-wrap: wrap;
    }
    > li {
      margin: 1rem 0;
      flex-shrink: 0;
      @include tablet {
        width: 25%;
      }
    }
  }
  .AttendeePreview {
    flex-direction: row;
    align-items: center;
    @include tablet {
      flex-direction: column;
    }
    > img {
      width: $img_w * 1.25;
      height: $img_w * 1.25;
      border-radius: $img_w * 1.25;
      border: none;
      margin-right: 1rem;
      @include tablet {
        margin-right: 0;
        margin-bottom: .5rem;
      }
    }
    .RankOne {
      border: 5px solid color(rank-1);
    }
    .RankTwo {
      border: 5px solid color(rank-2);
    }
    .RankThird {
      border: 5px solid color(rank-3);
    }
    > strong {
      display: flex;
      margin-right: .5rem;
    }
    > span {
      font-weight: 300;
      font-size: .9rem;
      padding: 0 .25rem;
      color: #fff;
      border-radius: .25rem;
      background-color: color(secondary);
    }
  }
  .AttendeeTrial {
    color: color(primary);
  }
}
