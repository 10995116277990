@import './styles/variables';
@import './styles/mixins';

.ProfilePage {
	padding: 1rem 0 2rem;
	> section {
		padding: 1rem 1.5rem;
		@include tablet {
			width: $width-tablet;
			margin: 0 auto;
		}
		&:last-of-type {
			margin-bottom: 1rem;
		}
		h2 {
			@include heavy-underline;
			align-self: flex-start;
			text-transform: uppercase;
			flex-direction: row;
			margin-bottom: 1rem;
		}
		.edit-userInfo-button {
			margin-left: 1rem;
			> img {
				width: 1.5rem;
			}
		}
	}
	> button {
		@include button-warning;
		padding: 1rem;
		margin: auto 1.5rem 0;
		@include mobile {
			width: calc(#{$width-mobile} - 3rem);
			margin: auto auto 0;
		}
	}
	.UserInvoices {
		margin-top: 1.5rem;
	}
}

.UserInfos {
	> fieldset {
		&:disabled {
			label {
				border-color: transparent;
			}
		}
		label {
			@include input-text;
		}
	}
	> button {
		@include button-secondary;
		padding: .75rem;
		margin-top: 2rem;
	}
}

.UserInvoices {
	> ul {
		> li {
			flex-direction: row;
			align-items: center;
			padding: .25rem 0;
			p {
				flex-direction: row;
				flex-grow: 1;
				span {
					font-weight: 500;
				}
				strong {
					margin-left: auto;
				}
			}
			a {
				margin-left: 1rem;
				img {
					width: 1.5rem;
				}
			}
		}
	}
}

.PaymentInfos {
	> p {
		flex-direction: row;
		align-items: center;
		> button {
			margin-left: 1rem;
			> img {
				width: 1.5rem;
			}
		}
	}
}