@import "styles/app";

.Place {
  margin-top: -5rem;
  > header {
    position: relative;
    > button {
      @include button;
      position: absolute;
      color: #fff;
      left: 1rem;
      top: 6rem;
      padding-left: .75rem;
      background-image: url(./icons/chevron-left--light.svg);
      background-position: left -.5rem center;
      background-repeat: no-repeat;
      background-size: 1.25rem;
      z-index: 1;
      @include tablet {
        left: calc((100% - #{$width-tablet}) / 2 + 1.5rem);
      }
    }
    > img {
      height: 50vh;
      width: 100%;
			object-fit: cover;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    > h1 {
      position: absolute;
      top: 0;
      left: 0;
      padding: 4rem 1.5rem 1.5rem;
      text-align: center;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(to left top, #{transparentize(#00ff83, .5)}, #{transparentize(#0b7a75, .3)});
      color: #fff;
      align-items: center;
      justify-content: center;
      text-shadow: 0 0 .5rem color(secondary);
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
  }
  > section {
    padding:1.5rem;
    align-items: flex-start;
    &:first-of-type {
      padding-top: 3rem;
    }
    &:last-of-type {
      padding-bottom: 3rem;
    }
    @include tablet {
      width: $width-tablet;
      margin: 0 auto;
    }
    > h2 {
      text-transform: uppercase;
      margin-bottom: 1rem;
      @include heavy-underline;
    }
    > p {
      white-space: pre-wrap;
    }
  }
  .Planning {
    margin: 0 -1.5rem;
    width: calc(100% + 3rem);
  }
}