@import "./styles/variables";
@import "./styles/mixins";

.Feedbacks {
	margin-top: -5rem;
	> header {
		position: relative;
		> img {
			height: 50vh;
			width: 100%;
			object-fit: cover;
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);
		}
		> h1 {
			position: absolute;
			top: 0;
			left: 0;
			padding: 4rem 1.5rem 1.5rem;
			text-align: center;
			height: 100%;
			width: 100%;
			background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
			color: #fff;
			align-items: center;
			justify-content: center;
			text-shadow: 0 0 .5rem color(secondary);
			font-size: 2.25rem;
			line-height: 2.5rem;
		}
	}

	.feedback-button {
		@include button-primary;
		padding: .75rem 1.5rem;
	}
	.feedback-button:disabled {
		@include button-disabled;
		padding: .75rem 1.5rem;
	}

	> section {
		padding: 1.5rem 0;

		@include tablet {
			width: $width-tablet;
			margin: 0 auto;
		}
		@include desktop {
			width: $width-desktop;
		}

		> ul {
			padding: 0 1.5rem;
			text-align: center;
			@include tablet {
				flex-direction: row;
				flex-wrap: wrap;
			}
			> li {
				padding: .5rem;
				@include tablet {
					padding: 2rem;
					width: 50%;
				}
			}
		}

		> div {
			padding: 0 1.5rem;
			p {
				margin-bottom: .5rem;
			}
			> button {
				img {
					width: 2rem;
				}
			}
		}

		> em {
			padding: .75rem;
		}

	}

}

.feedbacks-details {
	text-align: center;
	p {
		display: inline;
		strong {
			display: inline;
		}
	}

	ul.review-stars-rating {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		li:first-child {
			margin-right: .5rem;
		}
		li {
			margin-left: -.1rem;
		}
	}
}
