@import "./styles/variables";
@import "./styles/mixins";

.Plans {
	background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
	padding: 4rem 0 2rem;
	> h1 {
		color: #fff;
		padding: 2rem 1.5rem 0;
		@include tablet {
			margin: 0 auto;
			width: calc(#{$width-tablet} - 3rem);
		}
	}
}
