@import "./styles/variables";
@import "./styles/mixins";

.ClientsList {
	border-left: 1.5rem solid color(text);
	> ul {
		flex-direction: row;
		flex-wrap: wrap;
		> li {
			width: calc(100% / 2);
			@include tablet {
				width: calc(100% / 3);
			}
			@include desktop {
				width: calc(100% / 6);
			}
		}
	}
}

.ClientPreview {
	img {
		padding: .5rem;
		height: 5rem;
		object-fit: contain;
		@include desktop {
			height: 7rem;
			padding: 1.5rem;
			filter: saturate(0);
			opacity: .3;
			transition: all .3s;
			&:hover {
				filter: none;
				opacity: 1;
			}
		}
	}
}
