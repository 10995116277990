@import "./styles/variables";
@import "./styles/mixins";

.BookModal {
	section {
		p {
			margin-bottom: 1rem;
			strong {
				margin: .5rem 0;
			}
		}
		.error {
			font-weight: 500;
			color: color(warning);
		}
	}

	&.BookModal_Failure {
		.MsgPage {
			padding: 0;
		}
	}
}

.booking-modal-success {
	> section {
		> div {
			>.MsgPage {
				padding: 0;
			}
			> a {
				@include button-primary;
				padding: .75rem 1.5rem;
				margin-bottom: -1rem;
			}
		}
	}
}

