@import "./styles/variables";
@import "./styles/mixins";

.Footer {
	background-color: color(footer);
	color: #fff;
	display: none;
	@include desktop {
		display: flex;
	}
	> section {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		padding: 3rem 1.5rem;
		.logo-menu {
			position: absolute;
			bottom: 0;
			z-index: 0;
			margin: -10rem 0 -2rem -6.5rem;
			@include tablet {
				width: 30rem;
			}
			@include desktop {
				width: 35rem;
				margin-bottom: -3rem;
			}
		}
		.main-nav, .secondary-nav {
			> p {
				font-size: 2rem;
				line-height: 1.8rem;
				font-weight: 700;
				margin-bottom:2rem;
				padding-bottom: 0.5rem;
				border-bottom: 3px solid #00EA6F;
			}
			z-index: 1;
			margin-right: 5rem;
			> ul {
				align-items: flex-start;
				li {
					margin-bottom: 0.2rem;
					a, button {
						font-size: 1.0rem;
						line-height: 1.8rem;
						font-weight: 500;
						&.active {
							color: color(highlight);
						}
						@include desktop {
							&:hover {
								color: color(primary);
							}
						}
					}
				}
			}
		}
		
		> div {
			z-index: 1;
			> img {
				height: 6rem;
			}
			.social-nav {
				margin-bottom: auto;
				> ul {
					flex-direction: row;
					> li {
						> a {
							height: 4rem;
							width: 4rem;
							align-items: center;
							justify-content: center;
							border-radius: 4rem;
							&:hover {
								&.facebook {
									background-color: color(fb);
								}
								&.instagram {
									background-color: color(ig);
								}
								&.twitter {
									background-color: color(tw);
								}
								&.youtube {
									background-color: color(yt);
								}
								&.linkedin {
									background-color: color(li);
								}
							}
							> img {
								width: 2rem;
							}
						}
					}
				}
			}
		}
	}
	> footer {
		background-color: color(footer--dark);
		padding: 0.5rem;
		p {
			font-size: .8rem;
			text-align: center;
		}
	}
}