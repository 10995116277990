@import "./styles/variables";
@import "./styles/mixins";

.Modal {
	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
	background-color: transparent;
	border: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: 0;
	display: none;
	align-items: flex-start;
	z-index: 10;
	&[open] {
		display: flex;
	}
	> .modal-mask {
		background-color: rgba(0, 0, 0, .8);
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
	}
	> .modal-content {
		background-color: color(background);
		width: 100%;
		max-height: 80vh;
		position: fixed;
		bottom: 0;
		@include tablet {
			width: calc(#{$width-tablet} - 3rem);
			margin: 3rem auto;
			top: 0;
			bottom: auto;
			position: relative;
		}
		> header {
			background-color: color(header);
			font-size: 1.4rem;
			line-height: 2rem;
			padding: 1rem 1.5rem;
			font-weight: 700;
			color: #fff;
			flex-shrink: 0;
		}
		> section {
			padding: 1.5rem;
			overflow-y: auto;
			footer {
				margin-top: 1.5rem;
				flex-shrink: 0;
				@include tablet {
					flex-direction: row-reverse;
				}
				> button {
					&:disabled {
						@include button-disabled;
					}
					& + button {
						margin-top: .5rem;
						margin-bottom: -.5rem;
						@include tablet {
							margin: 0 1rem 0 0;
						}
					}
				}
				.modal-confirm-button {
					@include button-primary;
					padding: .75rem 1.5rem;
				}
				.modal-cancel-button {
					@include button;
					padding: .75rem 1.5rem;
				}
				.modal-warning-button {
					@include button-warning;
					padding: .75rem 1.5rem;
				}
			}
		}
	}
}