@import "./styles/variables";
@import "./styles/mixins";

.Legal {
	margin-top: -5rem;
	> header {
		background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
		color: #fff;
		padding: 7.5rem 1.5rem 2.5rem;
		h1 {
			@include tablet {
				text-align: center;
			}
		}
	}
	> section {
		padding: 1.5rem;
		@include tablet {
			width: $width-tablet;
			margin: 1rem auto;
		}
		@include desktop {
			width: $width-desktop;
			margin: 2rem auto;
		}
		> div {
			> p {
				display: inline;
				margin-bottom: 1rem;
				> * {
					display: inline;
				}
				a {
					text-decoration: underline;
				}
			}
		}
	}
}