@import "variables";
@import "reset";
@import "fonts";
@import "mixins";


.callToAction {
  @include button-secondary;
  background-color: #FE5E41;
  border-color: #FE5E41;
  &:hover {
    background-color: #C74C36;
    border-color: #C74C36;
  }
  padding: .75rem 1.5rem;
  margin: 1rem auto;
}