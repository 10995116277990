@import "./styles/variables";
@import "./styles/mixins";

.Teachers {
	width: 100%;
	margin-top: .5rem;

	> div {
		margin: .5rem;
		justify-content: center;
		flex-direction: row;
		> em {
			margin-right: .5rem;
			margin-left: .4rem;
			@include tablet {
				margin-left: 0;
			}
		}
		> svg {
			padding-right: .2rem;
			width: 1.8rem;
			height: 1.8rem;
			fill: color(default);
			@include dark-mode {
				fill: color(highlight);
			}
		}
	}
	> .TeacherList {
		@include tablet {
			width: calc(#{$width-tablet} - 3rem);
			margin: 0 auto;
		}
	}
}

.teacherActions {
	margin: auto;
}

.showTeacherFilters {
	@include button-primary;
	width: 5rem;
}

.TeacherFilter {
	> div {
		> label {
			flex-direction: row;
			font-size: 1rem;
			> span {
				padding-right: .5rem;
			}
		}
		> input {
			font-size: 1rem;
			font-weight: 300;
			outline: 0;
			border-width: 0 0 2px;
			border-color: color(primary);
		}
	}
}

.link-to-teacher {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.TeacherPreview {
	flex-direction: row;
	align-items: center;
	position: relative;
	flex-shrink: 0;
	width: 85%;
	> header {
		align-items: center;
		justify-content: center;
		margin-right: 2rem;
		margin-left: 2rem;
		img {
			$img-w: 4.5rem;
			width: $img-w;
			height: $img-w;
			border-radius: $img-w;
			flex-shrink: 0;
			object-fit: cover;
		}
	}
	> section {
		flex-direction: row;
		> div {
			display: flex;
			flex-direction: column;
			padding: .5rem;
			strong {
				font-weight: 500;
			}
			span {
				text-transform: capitalize;
				em {
					text-transform: none;
				}
			}
		}
	}
	> div {
		vertical-align: center;
		margin-left: auto;
		svg {
			width: 2rem;
			height: 2rem;
		}
	}
}
