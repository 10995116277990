@import "./styles/variables";
@import "./styles/mixins";

.offerTabParent {
	display: flex;
	overflow-x: scroll;
	@include tablet {
		overflow-x: auto;
	}
}

.offerTab {
	.newLabel {
		position:absolute;
		 top:-30px;
		 left:-20px;
		 width:100px;
		 overflow: ''
	}
	display: grid;
	gap: 1rem;
	margin: 1rem;
	h1 {
		margin-top:2rem;
		white-space: noWrap;
		text-align: center;
		color: white;
		border-radius: 3rem;
		padding: 2rem;
		text-transform: none !important; 
	}
	&.grid-4 {
		grid-template-columns: 95% 95% 95% 95% 2% !important;
	}
	&.grid-3 {
		grid-template-columns: 95% 95% 95% 2% !important;
	}
	&.grid-2 {
		grid-template-columns: 95% 95% 2% !important;
	}
	@include tablet {
		&.grid-4 {
			grid-template-columns: 1fr 1fr 1fr 1fr 0fr !important;
		}
		&.grid-3 {
			grid-template-columns: 1fr 1fr 1fr 0fr !important;
		}
		&.grid-2 {
			grid-template-columns: 1fr 1fr  0fr !important;
		}
		margin: auto;
	}
	h2 {
		color: black !important;
	}
	richText {
		margin: auto;
		text-align: center;
		> * {
			margin: auto;
			text-align: center;
		}
	}
	article {
		header {
			margin: 0rem;
			text-align:center;
		}
		section {
			margin: 0rem;
			text-align:center;
			padding: 0rem 1rem 1rem 1rem;
		}
		border-radius: 2rem;
		background-color: white;
		strong, u, p,span {
			color: black !important;
		}
	}
}
.OfferSelector {
	padding: 0 1.5rem;
	@include tablet {
		margin: 0 auto;
		width: calc(#{$width-tablet} - 3rem);
		flex-direction: row;
		flex-wrap: wrap;
	}
	> li {
		padding: .75rem 0;
		@include tablet {
			width: calc(100% / 2 - .75rem);
			&:nth-of-type(2n+1) {
				margin-right: 1.5rem;
			}
		}
	}
}

.OfferPreview {
	background-color: color(background);
	flex-grow: 1;
	cursor: pointer;
	&.current {
		margin: .5rem 1.5rem;
		@include tablet {
			width: calc(#{$width-tablet} - 6rem);
			margin: .5rem auto;
		}
		> header {
			position: relative;
			> span {
				position: absolute;
				right: 0;
				top: 0;
				padding: .25rem .5rem;
				background-color: color(secondary);
				color: #fff;
			}
		}
		> section {
			button {
				@include button-warning;
			}
		}
	}
	> header {
		padding: 0.5rem;
		flex-grow: 1;
		h2 {
			color: color(secondary);
		}
	}
	> section {
		padding: 1rem 1.5rem;
		align-items: center;
		> p {
			text-align: center;
			align-items: center;
			> b {
				font-size: 1.3rem;
				// line-height: 2.4rem;
				flex-direction: row;
			}
			> span {
				font-size: .9rem;
			}
		}
		button {
			@include button-secondary;
			border-radius: 10rem;
			padding: .75rem 1.5rem;
			margin-top: 0.3rem;
		}
	}
}