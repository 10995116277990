@import "styles/variables";
@import "styles/mixins";

.MessageList {
	flex-shrink: 0;
	> li {
		margin-bottom: 1rem;
		flex-shrink: 0;
		&.mine {
			align-items: flex-end;
			> article {
				> section {
					background-color: color(secondary);
					border-radius: .75rem .75rem 0 .75rem;
					color: #fff;
					align-items: flex-end;
					> time {
						color: #fff;
						opacity: .7;
					}
				}
			}
		}
		&.coach, &.admin {
			> article > section > span {
				color: color(secondary);
			}
		}
		&.admin {
			> article > section > span {
				color: color(warning);
			}
		}

		> article {
			max-width: 85%;
			> section {
				background-color: color(border--lighter);
				padding: 1rem 1rem.5rem 1rem;
				border-radius: .75rem .75rem .75rem 0;
				align-self: flex-start;
				> span {
					font-weight: 700;
					display: inline;
					em {
						display: inline;
						font-weight: 300;
						color: color(text);
						margin-left: .5rem;
						font-size: .9rem;
					}
				}
				> p {
					white-space: pre-wrap;
				}
				> time {
					font-size: .9rem;
					color: color(default);
					align-self: flex-end;
				}
			}
		}
	}
}
