@import "./styles/variables";
@import "./styles/mixins";

.CGV {
	margin-top: -5rem;
	> header {
		background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
		color: #fff;
		padding: 7.5rem 1.5rem 2.5rem;
		h1 {
			@include tablet {
				text-align: center;
			}
		}
	}
	> ol {
		@include tablet {
			width: $width-tablet;
			margin: 1rem auto;
		}
		@include desktop {
			width: $width-desktop;
			margin: 2rem auto;
		}
		> li {
			padding: 1rem 1.5rem;
			h2 {
				@include heavy-underline;
				text-transform: uppercase;
				margin-bottom: 1rem;
			}
			> div {
				margin-bottom: 1rem;
				> div, section {
					margin-bottom: 1rem;
					h3 {
						margin-bottom: .5rem;
					}
					div {
						margin-bottom: .5rem;
					}
				}
			}
		}
	}
	> aside {
		background-color: #f2f2f2;
		padding: 1.5rem;
		h2 {
			text-transform: uppercase;
			margin-bottom: 1rem;
			@include tablet {
				width: calc(#{$width-tablet} - 3rem);
				margin: 1rem auto;
			}
			@include desktop {
				width: calc(#{$width-desktop} - 3rem);
				margin: 2rem auto;
			}
		}
		> ol {
			@include tablet {
				width: calc(#{$width-tablet} - 3rem);
				margin: 1rem auto;
			}
			@include desktop {
				width: calc(#{$width-desktop} - 3rem);
				margin: 2rem auto;
			}
			> li {
				margin-bottom: 2rem;
				h3 {
					margin-bottom: .5rem;
				}
			}
		}
	}
}