@import './styles/variables';
@import './styles/mixins';

.Challenge {
	margin-top: -5rem;
	> header {
		position: relative;
		> button {
			@include button;
			position: absolute;
			color: #fff;
			left: 1rem;
			top: 6rem;
			padding-left: .75rem;
			background-image: url(./icons/chevron-left--light.svg);
			background-position: left -.5rem center;
			background-repeat: no-repeat;
			background-size: 1.25rem;
			z-index: 1;
			@include tablet {
				left: calc((100% - #{$width-tablet}) / 2 + 1.5rem);
			}
		}
		> img {
			height: 50vh;
			width: 100%;
			object-fit: cover;
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);
		}
		> h1 {
			position: absolute;
			top: 0;
			left: 0;
			padding: 4rem 1.5rem 1.5rem;
			text-align: center;
			height: 100%;
			width: 100%;
			background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
			color: #fff;
			align-items: center;
			justify-content: center;
			text-shadow: 0 0 .5rem color(secondary);
			font-size: 2.25rem;
			line-height: 2.5rem;
		}
	}
	> aside {
		z-index: 1;
		border-left: 1.25rem solid color(text);
		padding: 1.5rem 1.5rem 1rem 1rem;
		width: calc(100% - 3rem);
		margin-top: -3rem;
		background-color: color(background);
		align-items: flex-start;
		font-weight: 500;
		@include tablet {
			width: $width-tablet;
			margin: -3rem auto 1rem;
			align-items: normal;
		}
		strong {
			margin-bottom: .25rem;
		}
		> section {
			margin-top: 1rem;
			@include tablet {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}
			> div {
				> ul {
					flex-direction: row;
					flex-wrap: wrap;
					margin-bottom: .5rem;
					> li {
						background-color: color(header);
						font-size: .8rem;
						color: #fff;
						padding: 0 .5rem;
						border-radius: .25rem;
						margin-right: .25rem;
					}
				}
				> p {
					flex-direction: row;
					img	{
						width: 1.25rem;
						margin-right: .5rem;
					}
				}
			}
			> a {
				@include button-secondary;
				padding: .75rem 1.5rem;
				margin-top: 1rem;
				@include tablet {
					margin: 0 0 0 1rem;
				}
			}
		}
	}
	> section {
		padding: 1.5rem;
		align-items: flex-start;
		@include tablet {
			width: $width-tablet;
			margin: 0 auto;
		}
		> h2 {
			text-transform: uppercase;
			margin-bottom: 1rem;
			@include heavy-underline;
		}
		> p {
			white-space: pre-wrap;
		}
	}
}