@import "../styles/variables";
@import "../styles/mixins";

.Pagination {
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	margin: .5rem auto 0;
	> span {
		margin-right: 1rem;
		font-weight: 700;
		font-size: 1.2rem;
	}
	> nav {
		> ul {
			flex-direction: row;
			> li {
				flex-direction: row;
				span {
					font-weight: 700;
					margin: 0;
					width: 1.5rem;
					align-items: center;
				}
				button {
					width: 2rem;
					align-items: center;
					font-weight: 700;
					border: none;
					border-bottom: 5px solid transparent;
					&.active {
						border-color: color(secondary);
					}
				}
			}
		}
	}
}