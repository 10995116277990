
@import "./styles/variables";
@import "./styles/mixins";

.Enterprise {
	margin-top: -5rem;
	.cta {
		@include button-primary;
		padding: 1rem;
		margin-top: 2rem;
	}
	> header {
		height: calc(100vh - 8rem);
		position: relative;
		@include tablet {
			height: calc(100vh - 16rem);
		}
		> video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		> div {
			position: absolute;
			height: 100%;
			width: 100%;
			justify-content: center;
			padding: 4rem 0 1.5rem;
			background-color: transparentize(#000, .85);
			@include tablet {
				align-items: center;
			}
			> a {
				@include button-primary;
				margin-top: 4rem;
				padding: .75rem 1.5rem;
			}
			h1 {
				color: #fff;
				font-size: 2rem;
				line-height: 2.4rem;
				align-items: center;
				text-shadow: 0 0 .5rem rgba(0, 0, 0, .5);
				@include mobile {
					width: calc(#{$width-mobile} - 2rem);
					margin: 1rem auto;
				}
				img {
					margin-top: 4rem;
					width: 100%;
				}
				small {
					font-weight: 700;
					font-size: 2rem;
					line-height: 2.5rem;
					margin-top: 4rem;
					border-left: .5rem solid #fff;
					padding-left: 1rem;
					text-shadow: 0 0 .25rem rgba(0, 0, 0, .7);
				}
			}
		}
	}
	> section {
		padding: 4rem 0;
		@include tablet {
			padding: 5rem calc((100% - #{$width-tablet}) / 2);
		}
		@include desktop {
			padding: 6rem calc((100% - #{$width-desktop}) / 2);
		}
	}

	.enterprise-marketing {
		background-color: color(footer);
		color: #fff;
		> header {
			border-left: 1rem solid #fff;
			padding-left: 1rem;
			@include tablet {
				width: $width-tablet;
				margin: 0 auto;
			}
			h2 {
				font-weight: 900;
				text-transform: uppercase;
				font-size: 3rem;
				line-height: 4rem;
				margin-bottom: 1rem;
			}
		}
		> p {
			padding: 1rem;
			@include tablet {
				width: $width-tablet;
				margin: 0 auto;
			}
		}
		> ul.enterprise-stats {
			padding: 0 1.5rem;
			justify-content: space-around;
			margin-bottom: 2rem;
			@include tablet {
				width: $width-tablet;
				margin: 0 auto 2rem;
				flex-direction: row;
			}
			> li {
				padding: 1.5rem 0;
				> strong {
					font-weight: 900;
					font-size: 3rem;
					line-height: 3.5rem;
					text-transform: uppercase;
					flex-direction: row;
					color: color(primary);
					justify-content: center;
					sup {
						color: #fff;
					}
				}
				> p {
					font-weight: 700;
					text-align: center;
				}
			}
		}
		ul.enterprise-pillars {
			display: grid;
			margin-bottom: 2rem;
			align-items: center;
			@include tablet {
				padding: 1rem 1.5rem 0;
				grid-template-columns: 40% 60%;
				width: $width-tablet;
				margin: 0 auto;
			}
			strong {
				font-weight: 900;
				font-size: 2rem;
				line-height: 2.5rem;
				text-transform: uppercase;
				text-align: center;
				padding: 1rem 0;
				color: color(primary);
				@include tablet {
					text-align: left;
				}
			}
		}
	}
	.enterprise-offers {
		h2 {
			text-align: center;
			font-weight: 900;
			text-transform: uppercase;
			font-size: 3rem;
			line-height: 4rem;
			margin-bottom: 2rem;
		}
		> ul {
			display: grid;
			grid-gap: 3rem;
			@include tablet {
				width: auto;
				grid-template-columns: repeat(2, 1fr);
			}
			@include desktop {
				grid-template-columns: repeat(4, 1fr);
			}
			> li {
				header {
					border-left: .75rem solid color(footer);
					padding-left: .75rem;
					padding-right: 1rem;
					h3 {
						text-transform: uppercase;
						font-weight: 900;
						line-height: 2rem;
						font-size: 1.75rem;
						margin-bottom: 1rem;
						color: color(primary);
					}
					p {
						font-weight: 500;
					}
				}
				> p {
					padding: 1rem 1.5rem 0;
					font-weight: 500;
					strong {
						font-size: 1.25rem;
						margin-bottom: .5rem;
					}
				}
			}
		}
	}
	.enterprise-approach {
		background-color: color(footer);
		color: #fff;
		> header {
			border-left: 1rem solid #fff;
			padding-left: 1rem;
			padding-right: 1rem;
			@include tablet {
				width: $width-tablet;
				margin: 0 auto;
			}
			h2 {
				font-weight: 900;
				text-transform: uppercase;
				font-size: 3rem;
				line-height: 4rem;
				margin-bottom: 1rem;
			}
		}
		> ul {
			display: grid;
			grid-gap: 3rem;
			padding: 2rem;
			@include tablet {
				grid-gap: 4rem;
				grid-template-columns: repeat(2, 1fr);
				width: $width-tablet;
			}
			@include desktop {
				margin: 4rem auto 0;
			}
			> li {
				flex-direction: row;
				align-items: center;
				img {
					height: 6rem;
					width: 6rem;
					object-fit: contain;
					margin-right: 1rem;
				}
			}
		}
	}
	.enterprise-coaching, .enterprise-team-building {
		> header {
			> h2 {
				font-weight: 900;
				text-transform: uppercase;
				font-size: 3rem;
				line-height: 3rem;
				padding: 1rem;
				@include desktop {
					line-height: 4rem;
					text-align: center;
				}
			}
			> p {
				padding: 1rem;
				@include desktop {
					text-align: center;
				}
			}
		}
		$gap: 5rem;
		> ul {
			flex-direction: row;
			width: 100%;
			overflow-x: auto;
			@include desktop {
				margin: 1rem $gap;
				overflow: unset;
			}
			> li {
				@include desktop {
					width: calc(100% / 3);
				}
				> article {
					position: relative;
					@include desktop {
						overflow: hidden;
						transform: skewX(-11deg);
					}
					&:hover {
						> img {
							filter: none;
						}
						> div {
							background-color: transparent;
						}
					}
					> img {
						filter: grayscale(100%);
						object-fit: cover;
						height: 75vh;
						transition: all .3s;
						@include desktop {
							width: calc(100% + #{$gap} * 2);
							height: 80vh;
							max-height: 50rem;
							margin-left: -$gap;
							transform: skewX(11deg);
						}
					}
					> div {
						position: absolute;
						transition: all .3s;
						width: 100%;
						height: 100%;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						> strong {
							font-size: 2rem;
							line-height: 2rem;
							color: #fff;
							text-align: center;
							@include desktop {
								transform: skewX(11deg);
							}
						}
					}
					.cardio, .bootcamp {
						background-color: transparentize(#ef476f, .5);
					}
					.renfo, .combattant {
						background-color: transparentize(#28afb0, .5);
					}
					.zen, .orientation {
						background-color: transparentize(#ffd166, .5);
					}
				}
				> .modal-mask {
					display: none;
					&.active {
						display: none;
					}
					@include desktop {
						position: fixed;
						z-index: 999;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: transparentize(#424B54, .3);
						&.active {
							display: flex;
						}
					}
				}
				> aside {
					position: fixed;
					z-index: 1000;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: none;
					padding: 0;
					margin: 0;
					flex-direction: column-reverse;
					display: none;
					background-color: color(background);
					@include tablet {
						flex-direction: row;
						top: 6rem;
						left: calc((100% - #{$width-tablet}) / 2);
						height: 80vh;
						max-height: 50rem;
						width: $width-tablet;
					}
					&.active {
						display: flex;
					}
					> div {
						flex-grow: 1;
						padding: 1rem;
						@include desktop {
							padding: 2rem;
						}
						h3 {
							font-size: 2rem;
							line-height: 2.5rem;
							margin-bottom: 2rem;
						}
						> ul {
							overflow-y: auto;
							> li {
								margin-bottom: .5rem;
								font-weight: 500;
							}
						}
						> p {
							font-weight: 700;
							margin-bottom: 1rem;
						}
					}
					> img {
						height: 50%;
						object-fit: cover;
						@include tablet {
							width: 20rem;
							height: 100%;
						}
						@include desktop {
							width: 25rem;
						}
					}
					> button {
						position: absolute;
						right: 0;
						top: 0;
						padding: 1rem;
						img {
							width: 3rem;
						}
					}
				}
			}
		}
	}
	.enterprise-team-building {
		background-color: color(footer);
		color: #fff;
		> ul {
			> li {
				> article {
				}
				> .modal-mask {
					background-color: transparentize(#fff, .8);
				}
				> aside {
					background-color: color(footer);
					color: #fff;
				}
			}
		}
	}
	.enterprise-workshops {
		$gap: 5rem;
		background-color: color(footer);
		color: #fff;
		padding-top: 2rem;
		@include desktop {
			padding-top: 6rem;
		}
		> h2 {
			font-weight: 900;
			text-transform: uppercase;
			font-size: 3rem;
			line-height: 3rem;
			padding: 1rem;
			@include desktop {
				line-height: 4rem;
				padding: 0 1rem 5rem;
				text-align: center;
			}
		}
		> div {
			background-color: color(footer--dark);
			margin-bottom: 1rem;
			@include desktop {
				padding-left: 10rem;
				flex-direction: row;
			}
			&:nth-of-type(2) {
				@include desktop {
					flex-direction: row-reverse;
					padding: 0 10rem 0 0;
					margin-bottom: 3rem;
				}
				> div {
					> ul {
						> li {
							strong {
								grid-area: 1 / 1 / 2 / 2;
								padding-bottom: .5rem;
							}
							p {
								grid-area: 2 / 1 / 3 / 2;
								padding-top: 0;
							}
							span {
								grid-area: 1 / 2 / 3 / 3;
								justify-content: center;
								font-weight: 500;
								em {
									color: #fff;
									padding: 0;
									font-size: .875rem;
								}
							}
						}
					}
				}
			}
			> figure {
				@include desktop {
					overflow: hidden;
					transform: skewX(-11deg);
					height: 80vh;
					max-height: 50rem;
					width: 20rem;
					margin: -3rem 0;
					flex-shrink: 0;
				}
				img {
					object-fit: cover;
					height: 30rem;
					@include desktop {
						height: 100%;
						transform: skewX(11deg);
						width: calc(100% + #{$gap} * 2);
						margin-left: -$gap;
					}
				}
			}
			> div {
				width: 100%;
				padding: 0 1rem;
				@include desktop {
					padding: 3rem 5rem;
				}
				> h3 {
					padding: 2rem 0 1rem;
					font-size: 2rem;
					line-height: 2.5rem;
					margin-bottom: 1rem;
					margin-left: 1rem;
				}
				> ul {
					width: 100%;
					> li {
						width: 100%;
						display: grid;
						grid-template-columns: 70% 30%;
						&:nth-of-type(odd) {
							background-color: transparentize(#fff, .975);
						}
						* {
							padding: 1rem;
						}
						span {
							text-align: center;
							color: color(primary);
							background-color: transparentize(#fff, .975);
							font-weight: 500;
						}
					}
				}
				> p {
					font-weight: 700;
					padding: 2rem 1rem 1rem 0;
					flex-direction: row;
					align-items: center;
					&::before {
						content: url(./icons/brush-arrow--light.svg);
						width: 2rem;
						margin-right: 1rem;
					}
				}
			}
		}
	}
	.enterprise-workshop-plans {
		background-color: color(footer--dark);
		color: #fff;
		> h2 {
			font-weight: 900;
			text-transform: uppercase;
			font-size: 3rem;
			line-height: 3rem;
			padding: 1rem;
			@include desktop {
				line-height: 4rem;
				text-align: center;
			}
		}
		> ul {
			@include desktop {
				flex-direction: row;
			}
			margin: 0 auto;
			background-color: color(footer);
			color: #fff;
			> li {
				display: grid;
				@include desktop {
					grid-template-rows: 10rem 7rem 7rem 7rem 7rem 7rem;
				}
				&:nth-of-type(even) {
					background-color: transparentize(#fff, .975);
				}
				header, div {
					padding: 2rem;
					justify-content: center;
					align-items: center;
					@include desktop {
						padding: 0 2rem;
					}
					strong {
						font-weight: 900;
						text-transform: uppercase;
						font-size: 1.75rem;
						line-height: 2rem;
						text-align: center;
						margin-bottom: .5rem;
					}
				}
				header {
					background-color: color(footer--dark);
				}
				div {
					&:nth-of-type(even) {
						background-color: transparentize(#fff, .975);
					}
					&:last-of-type {
						> a {
							font-weight: 900;
							font-size: 1.75rem;
							line-height: 2rem;
							color: color(primary);
						}
					}
				}
			}
		}
	}
	.enterprise-references {
		h2 {
			text-align: center;
			text-transform: uppercase;
			font-weight: 900;
			margin-bottom: 2rem;
			font-size: 2rem;
			line-height: 2.5rem;
		}
		> ul {
			margin: 2rem auto;
			display: grid;
			grid-gap: 3rem;
			@include tablet {
				width: $width-tablet;
			}
			@include desktop {
			grid-template-columns: repeat(4, 1fr);

			}
			> li {
				height: 5rem;
				img {
					height: 100%;
					width: 100%;
					object-fit: contain;
				}
			}
		}
	}
	.enterprise-pack-cse {
		background-color: color(footer--dark);
		color: #fff;
		> h2 {
			font-weight: 900;
			text-transform: uppercase;
			font-size: 3rem;
			line-height: 3rem;
			padding: 1rem;
			@include desktop {
				line-height: 4rem;
				text-align: center;
			}
		}
		> p {
			align-items: center;
			padding: 2rem;
			font-size: 2rem;
			line-height: 2rem;
		}
		> ul {
			@include desktop {
				flex-direction: row;
				width: 75%;
			}
			margin: 0 auto;
			background-color: color(footer);
			color: #fff;
			> li {
				display: grid;
				@include desktop {
					grid-template-rows: 10rem;
					width: 100%;
				}
				&:nth-of-type(even) {
					background-color: transparentize(#fff, .975);
				}
				header, div {
					padding: 2rem;
					justify-content: center;
					align-items: center;
					@include desktop {
						padding: 0 2rem;
					}
					strong {
						font-weight: 900;
						text-transform: uppercase;
						font-size: 1.75rem;
						line-height: 2rem;
						text-align: center;
						margin-bottom: .5rem;
					}
				}
				header {
					&:nth-of-type(even) {
						background-color: transparentize(#fff, .975);
					}
					position: relative;
					> img {
						position: absolute;
						width: 75%;
					}
					strong {
						position: relative;
					}
				}
			}
		}
		> div {
			width: 75%;
			margin: 0 auto 2rem;
			padding: 3rem 1.5rem 2rem;
			align-items: center;
			background-color: transparentize(#fff, .975);
			strong {
				font-size: 1.25rem;
				line-height: 2rem;
				margin-bottom: 1rem;
			}
			ul {
				> li {
					margin-bottom: 1rem;
					font-size: 1.25rem;
				}
			}
		}
		> em {
			text-align: center;
		}
	}
	.enterprise-contact {
		background-color: color(footer--dark);
		color: #fff;
		h2 {
			text-align: center;
			text-transform: uppercase;
			font-weight: 900;
			margin-bottom: 2rem;
			font-size: 2rem;
			line-height: 2.5rem;
		}
		> ul {
			margin: 0 auto;
			display: grid;
			grid-gap: 2rem;
			@include tablet {
				width: $width-tablet;
			}
			> li {
				align-items: center;
				> figure {
					position: relative;
					margin-bottom: 2rem;
					> img {
						width: 16rem;
						height: 16rem;
						border-radius: 100rem;
					}
					> a {
						position: absolute;
						bottom: 0;
						right: 0;
						img {
							width: 4rem;
							height: 4rem;
						}
					}
				}
				> strong {
					font-size: 2rem;
					line-height: 2.5rem;
					color: color(primary);
					text-align: center;
					margin-bottom: 2rem;
				}
				> a {
					font-size: 1.25rem;
					font-weight: 500;
					margin-bottom: 1rem;
				}
			}
		}
	}
	.enterprise-coaches {
		$gap: 5rem;
		background-color: color(highlight);
		h2 {
			font-weight: 900;
			text-transform: uppercase;
			font-size: 3rem;
			line-height: 3rem;
			padding: 1rem;
			@include desktop {
				line-height: 4rem;
				text-align: center;
			}
		}
		> div {
			display: grid;
			@include desktop {
			grid-template-columns: repeat(4, 1fr);

			}
			> figure {
				overflow: hidden;
				transform: skewX(11deg);
				height: 80vh;
				max-height: 50rem;
				width: 20rem;
				flex-shrink: 0;
				img {
					transform: skewX(-11deg);
					width: calc(100% + #{$gap} * 2);
					height: 100%;
					margin-left: -$gap;
					object-fit: cover;
				}
			}
			> p {
				align-items: flex-start;
				padding: 1rem;
				@include desktop {
					font-size: 1.5rem;
					&:first-of-type {
						margin-top: auto;
					}
				}
				strong {
					font-size: 3em;
					line-height: 1em;
					font-weight: 900;
					flex-direction: row;
					position: relative;
					justify-content: flex-start;
					sup {
						position: absolute;
						right: -1rem;
						top: -.5rem;
					}
				}
				span, em {
					font-size: 1em;
					line-height: 1.25em;
					font-weight: 500;
					margin-bottom: 1rem;
				}
			}
		}
	}
	.enterprise-brochure-cta {
		background-color: color(footer-dark);
		text-align: center;
		> h2 {
			text-transform: uppercase;
			font-weight: 900;
			font-size: 2rem;
			line-height: 2rem;
			margin-bottom: 1rem;
			padding: 0 2rem;
		}
		> div {
			padding: 0 1.5rem;
			@include tablet {
				align-items: center;
			}
			a {
				@include button-primary;
				margin-top: 1rem;
				padding: .75rem 1.5rem;
			}
		}
	}
}
