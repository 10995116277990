@import "./styles/variables";
@import "./styles/mixins";

.Confidentialite {
	margin-top: -5rem;
	> header {
		background-image: linear-gradient(to left top, #{transparentize(#00ff83, .4)}, #{transparentize(#0b7a75, .2)});
		color: #fff;
		padding: 7.5rem 1.5rem 2.5rem;
	}
	section {
		padding: 2rem;
		padding-left: 5rem;
		strong {
			display: inline;
		}
	}
	table {
		text-align: center;
	}
	th, td {
		border: 1px solid black;
	}
	p {
		padding:1rem;
	}
	ol {
		list-style: disc;
		li { 
			display: list-item !important;
			margin-left: 2rem;
			margin-bottom: 0.5rem;
			margin-top: 0.5rem;
		}
	}
}